<template>
  <Draggable
    id="dropzone"
    v-model="selected.sections"
    group="elements"
    :move="highlightSection"
    :empty-insert-threshold="100"
    @end="tryAddingSection"
  >
    <div
      v-for="(section, sectionIndex) in selected.sections"
      :key="sectionIndex"
      @click="updateSelectedField(sectionIndex)"
    >
      <Section 
        :section="section"
        :section-index="sectionIndex"
        @delete="deleteSection(sectionIndex)"
      />
    </div>
  </Draggable>
</template>

<script >
// libs
import Draggable from 'vuedraggable';
// components
import Section from './section/Section.vue';
// services
import { deleteVariableWithReferenceService } from '@/services/application-service/variableRequests';
// composables
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useDropzone } from './dropZone';
import { useRoute } from '@/hooks/vueRouter';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';

//-- composables --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    highlightSection,
    tryAddingSection
  } = useDropzone();
  const {
    selected,
    selectedFieldIndices,
    selectedSection
  } = useFormBuilder();
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const updateSelectedField = sectionIndex => {
    selectedFieldIndices.value.sectionIndex = sectionIndex;
  };

  //-- delete section logic --//
  const deleteSection = async index => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      selectedFieldIndices.value = {
        sectionIndex: null,
        columnIndex: null,
        fieldIndex: null,
        type: null
      };
      const fieldsToBeDeleted = selected.value.sections[index].fields.flatMap(columns => columns.filter(element => element.id));
      const fieldIds = fieldsToBeDeleted.map(field => field.id);
      const fieldVariableReferences = moduleStore.moduleVariables.filter(variable => fieldIds.some(id => variable.reference.includes(id))).map(variable => variable.reference);
      await moduleStore.deleteVariables(appId, moduleId, fieldVariableReferences);

      // @NOTE: Removing variable references from JSON may not be required once we have better variable search feature in builder

      // delete variable references from UI
      // const mentionIds = new Set(selected.value.sections.flatMap(section => section.fields.flatMap(field => field.map(ele => ele.id))));
      // moduleGraphStore.deleteVariableReferencesFromAllScreens([...mentionIds]);

      selected.value.sections.splice(index, 1);
      if (!selected.value.sections.length) {
        let rules = [];
        moduleStore.variables.forEach(variable => {
          rules.push({
            type: 'text',
            id: variable.reference,
            label: variable.name,
            operators: ['equals', 'does not equal', 'less than', 'more than', 'less than or equal to', 'more than or equal to', 'empty', 'not empty', 'contains', 'does not contain', 'begins with', 'ends with']
          });
        });
        selected.value.sections.push({
          columns: '1',
          rules: rules,
          show: false,
          condition: {
            logicalOperator: 'all',
            children: []
          },
          fields: [[{
            type: 'hidden'
          }]]
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  return {
    highlightSection,
    tryAddingSection,
    selected,
    updateSelectedField,
    deleteSection
  };
};
__sfc_main.components = Object.assign({
  Draggable,
  Section
}, __sfc_main.components);
export default __sfc_main;
</script>
