<template>
  <div class="mt-2">
    <b-notification
      type="is-primary is-light"
      :closable="false"
      class="mb-5 mr-2 has-text-centered"
    >
      This module is executed <strong>{{ selectedExecutionTime === 'Always' ? 'always' : 'at specific times' }}.</strong>
    </b-notification>

    <BaseSelect
      v-model="selectedExecutionTime"
      label="Execution time"
    >
      <option
        v-for="(executionTime, index) in executionTimes"
        :key="index"
      >
        {{ executionTime }}
      </option>
    </BaseSelect>

    <!-- <ExecuteAlways v-if="selectedExecutionTime === 'Always'" /> -->
    <Scheduler
      v-if="selectedExecutionTime === 'Specific time / Cron job'"
      class="my-2"
    />
  </div>
</template>

<script >
// libs
import { computed, ref } from '@vue/composition-api';
// components
import Scheduler from '@/modules/builder/components/module-sidebar/start/Scheduler.vue';
// import ExecuteAlways from '@/modules/builder/components/module-sidebar/start/ExecuteAlways.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const executionTimes = ref(['Always', 'Specific time / Cron job']);
  const selectedExecutionTime = computed({
    get() {
      const startNode = moduleGraphStore.selectedNode?.data;
      if (startNode && startNode.type) {
        return startNode.type;
      }
      return 'Always';
    },
    set(value) {
      saveSidebarInfo(value);
    }
  });
  const saveSidebarInfo = async executionType => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      const nodeInfo = await moduleGraphStore.getNodeInfoById(appId, moduleId, moduleGraphStore.selectedNodeId);
      let newNodeInfo = null;
      if (executionType === 'Always') {
        newNodeInfo = {
          type: 'Always',
          name: 'Start'
        };
      } else {
        newNodeInfo = {
          name: 'Scheduler',
          executionTime: 'Always',
          cronJob: {
            minute: '*',
            hour: '*',
            day: '*',
            month: '*',
            weekday: '*'
          }
        };
      }
      if (nodeInfo) {
        await moduleGraphStore.updateNodeInfo(appId, moduleId, moduleGraphStore.selectedNodeId, newNodeInfo);
      } else {
        await moduleGraphStore.addNodeInfo(appId, moduleId, moduleGraphStore.selectedNodeId, newNodeInfo);
      }
      moduleGraphStore.updateSelectedNodeData({
        type: executionType,
        name: 'Start'
      });
    } catch (err) {
      console.error(err);
    }
  };
  return {
    executionTimes,
    selectedExecutionTime
  };
};
__sfc_main.components = Object.assign({
  BaseSelect,
  Scheduler
}, __sfc_main.components);
export default __sfc_main;
</script>
