<template>
  <b-collapse
    v-model="isTileOptionsOpen"
    aria-id="contentIdForA11y2"
    class="panel mt-2"
    animation="slide"
  >
    <template #trigger>
      <div
        class="panel-heading"
        role="button"
        aria-controls="contentIdForA11y2"
        :aria-expanded="isTileOptionsOpen"
      >
        Tiles
      </div>
    </template>
    <div class="panel-body">
      <div
        v-for="(tile,index) in basicProperties.tiles"
        :key="tile.id"
      >
        <b-collapse
          v-model="isTileOpen[index]"
          aria-id="contentIdForA11y2"
          class="panel mt-2"
          animation="slide"
        >
          <template #trigger>
            <div
              class="panel-heading"
              role="button"
              aria-controls="contentIdForA11y2"
              :aria-expanded="isTileOptionsOpen"
            >
              Tile {{ index + 1 }}
            </div>
          </template>
          <div class="panel-body">
            <div class="is-flex is-justify-content-end">
              <b-switch
                v-model="basicProperties.tiles[index].arrangeImageFirst"
                @input="onArrangeimageFirst"
              >
                Arrange Image First
              </b-switch>
            </div>
            <BaseInput
              v-model="basicProperties.tiles[index].label"
              class="mt-2"
              label="Label"
            />
            <b-field
              label="Image"
              custom-class="base-label default"
            >
              <div class="is-block">
                <div class="is-flex is-justify-content-end mb-2">
                  <b-switch
                    v-model="basicProperties.tiles[index].file_upload"
                    @input="onFileUploadInput(index)"
                  >
                    File Upload
                  </b-switch>
                </div>
                <b-upload
                  v-if="basicProperties.tiles[index].file_upload"
                  v-model="file[index]"
                  expanded
                  accept="image/*"
                  :loading="isUploading[index]"
                  @input="fileUpload(index)"
                >
                  <a class="button is-primary is-fullwidth">
                    <b-icon icon="upload" />
                    <span> Click to upload </span>
                  </a>
                </b-upload>
                <BaseInput
                  v-else
                  v-model="basicProperties.tiles[index].image_url"
                  label="Enter Url"
                />
              </div>
            </b-field>
          </div>
        </b-collapse>
      </div>
    </div>
  </b-collapse>
</template>

<script >
import { ref, set } from '@vue/composition-api';
import { useBasicProperties } from '../basicProperties';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import axios from 'axios';
import { useBuefy } from '@/hooks/buefy';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const file = ref({});
  const isUploading = ref({});
  const buefy = useBuefy();
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  const isTileOptionsOpen = ref(false);
  const isTileOpen = ref({});
  const fileUpload = async index => {
    try {
      isUploading.value[index] = true;
      if (!file.value?.[index]) {
        return;
      }
      const {
        name
      } = file.value[index];
      const formData = new FormData();
      let fileName = file.value[index].name?.split('.');
      fileName = fileName.slice(0, fileName.length).join('.');
      formData.append(fileName, file.value[index]);
      await axios({
        method: 'POST',
        url: process.env.VUE_APP_APP_SERVICE_DOMAIN + '/upload',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      basicProperties.value.tiles[index].image = `${process.env.VUE_APP_APP_SERVICE_DOMAIN}/${name}`;
    } catch (err) {
      console.error(err);
      buefy.toast.open({
        message: 'Unexpected Error, failed to upload file',
        position: 'is-top',
        type: 'is-danger'
      });
    } finally {
      isUploading.value[index] = false;
    }
  };
  const onFileUploadInput = index => {
    basicProperties.value.tiles[index].image = null;
    basicProperties.value.tiles[index].image_url = null;
  };
  const onArrangeimageFirst = () => {
    set(basicProperties.value, 'contentArrangement', 'custom');
  };
  return {
    file,
    isUploading,
    basicProperties,
    isTileOptionsOpen,
    isTileOpen,
    fileUpload,
    onFileUploadInput,
    onArrangeimageFirst
  };
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.panel-heading{
  background-color: lightgrey;
  color: black;
  font-size: 14px;
  font-weight: 400;
}
.panel-body{
  padding: 1rem;
}
</style>