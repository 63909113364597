import { ref } from '@vue/composition-api';

const actionsList = ref([
    {
        type: 'Build an API',
        description: 'Description...',
        icon: 'api',
    },
    {
        type: 'Create Document',
        description: 'Description...',
        icon: 'file-document-edit-outline',
    },
    {
        type: 'Map/Pair Data',
        description: 'Description...',
        icon: 'code-json',
    },
    {
        type: 'Read/Write To Remote Database',
        description: 'Description...',
        icon: 'database-export',
    },
    {
        type: 'Send API Request',
        description: 'Description...',
        icon: 'send-check',
    },
    {
        type: 'Send Email',
        description: 'Description...',
        icon: 'email-send',
    },
    {
        type: 'Local Database Actions',
        description: 'Description...',
        icon: 'database',
    },
    {
        type: 'Read a file',
        description: 'Description...',
        icon: 'file-eye',
    },
    {
        type: 'Move a file',
        description: 'Description...',
        icon: 'file-swap',
    },
    {
        type: 'Upload File',
        description: 'Description...',
        icon: 'file-upload',
    },
    {
        type: 'Merge Documents',
        description: 'Description...',
        icon: 'text-box-multiple',
    },
    {
        type: 'Redirect',
        description: 'Description...',
        icon: 'arrow-right',
    }
]);

export const useActionSidebar = () => {
    
    return {
        actionsList
    };
};
