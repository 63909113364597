<template>
  <div>
    <BaseInput
      v-model="salesChannelInfo.name"
      label="Node name"
    />

    <BaseSelect
      v-model="salesChannelInfo.action"
      placeholder="e.g. Pricing integration"
      label="Integration action"
    >
      <option
        v-for="(action, index) in options.actions"
        :key="index"
      >
        {{ action }}
      </option>
    </BaseSelect>

    <BaseSelect
      v-model="salesChannelInfo.product"
      placeholder="e.g. Car Insurance"
      label="Product line"
    >
      <option
        v-for="(product, index) in options.products"
        :key="index"
      >
        {{ product }}
      </option>
    </BaseSelect>

    <div v-if="salesChannelInfo.product">
      <div class="columns ">
        <div class="column is-9">
          <BaseInput
            v-model="filter.name"
            placeholder="e.g. Check24"
            label="Sales Channel"
            @input="filterByName"
          />
        </div>

        <div class="column is-3">
          <b-button
            style="margin-top: 22px"
            type="is-primary"
            @click="filtersActive = !filtersActive"
          >
            <b-icon
              icon="filter"
              size="is-small"
              class="mr-1"
            />
            Filters
          </b-button>
        </div>
      </div>

      <b-row v-if="filtersActive">
        <b-button
          size="is-small"
          :type="filter.category !== 'Direct to customer aggregators' ? 'is-light' : 'is-primary'"
          class="mr-3 mb-3"
          @click="filterByCategory('Direct to customer aggregators')"
        >
          <b-icon
            icon="plus"
            size="is-small"
            class="mr-1"
          />
          Direct to customer aggregators
        </b-button>
        <b-button
          size="is-small"
          :type="filter.category !== 'Broker aggregators' ? 'is-light' : 'is-primary'"
          class="mr-3 mb-3"
          @click="filterByCategory('Broker aggregators')"
        >
          <b-icon
            icon="plus"
            size="is-small"
            class="mr-1"
          />
          Broker aggregators
        </b-button>
        <b-button
          size="is-small"
          :type="filter.category !== 'Broker software' ? 'is-light' : 'is-primary'"
          class="mr-3 mb-3"
          @click="filterByCategory('Broker software')"
        >
          <b-icon
            icon="plus"
            size="is-small"
            class="mr-1"
          />
          Broker software
        </b-button>
      </b-row>
      <div
        v-for="(channel, index) in selectedChannels"
        :key="index"
        style="height: 60px; overflow: hidden;"
        class="card p-3 mb-3"
        :class="salesChannelInfo.channel === channel.name ? 'channel-selected-card' : ''"
      >
        <div class="columns">
          <div class="column is-3">
            <img
              :src="require('@/assets/sales-channels/' + channel.image)"
              width="100"
              :alt="channel.name"
              :title="channel.name"
            >
          </div>
          <div class="column is-6">
            <p>{{ channel.name }}</p>
          </div>
          <div class="column is-3">
            <b-button
              :disabled="salesChannelInfo.channel === channel.name"
              :type="salesChannelInfo.channel === channel.name ? 'is-primary' : 'is-light'"
              @click="selectChannel(channel.name)"
            >
              Select
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
// libs
import { ref, watch } from '@vue/composition-api';
// components
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
// services
import { variablesIntegrationService } from '@/services/integration-service/integrationVariablesRequests';
import { createVariableService } from '@/services/application-service/variableRequests';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
import { refResetter } from '@/hooks/utils';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';

//-- use composables --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const selectedChannels = ref([]);
  const options = ref({
    channels: [{
      name: 'Check24',
      category: 'Direct to customer aggregators',
      image: 'check24.png'
    }, {
      name: 'Verivox',
      category: 'Direct to customer aggregators',
      image: 'verivox.png'
    }, {
      name: 'Covomo',
      category: 'Broker aggregators',
      image: 'covomo.png'
    }, {
      name: 'Franke & Bornberg',
      category: 'Broker aggregators',
      image: 'fb.png'
    }, {
      name: 'Morgen & Morgen',
      category: 'Broker aggregators',
      image: 'mundm.png'
    }, {
      name: 'Mr-Money',
      category: 'Broker aggregators',
      image: 'mrmoney.webp'
    }, {
      name: 'Nafi',
      category: 'Broker aggregators',
      image: 'nafi.png'
    }, {
      name: 'Softfair',
      category: 'Broker aggregators',
      image: 'softfare.jpeg'
    }, {
      name: 'Smart Insurtech',
      category: 'Broker aggregators',
      image: 'smartinsurtech.png'
    }, {
      name: 'AMS Assfinet',
      category: 'Broker software',
      image: 'assfinet.png'
    }, {
      name: 'IWM Software',
      category: 'Broker software',
      image: 'iwm.png'
    }, {
      name: 'Mein MVP',
      category: 'Broker software',
      image: 'meinMVP.webp'
    }],
    products: ['Berufsunfähigkeit', 'Fahrradversicherung', 'Gesetzliche Krankenkassen', 'Grundfähigkeitsversicherung', 'Handyversicherung', 'Hausratversicherung', 'Heilpraktiker und Brille', 'Hundehaftpflicht', 'Hundekrankenversicherung', 'Katzenversicherung', 'Kfz-Versicherung', 'Konto-Schutzbrief', 'Krankenhauszusatz', 'Moped und E-Scooter', 'Motorradversicherung', 'Neubauversicherung', 'Pferde-OP-Versicherung', 'Pferdehaftpflicht', 'Pflegeversicherung', 'Private Krankenversicherung', 'Privathaftpflicht', 'Rechtsschutzversicherung', 'Reiseversicherung', 'Rentenversicherung', 'Risikolebensversicherung', 'Schutzbrief/Automobilclub', 'Sofort-Rente', 'Sterbegeldversicherung', 'Unfallversicherung', 'Wohngebäudeversicherung', 'Zahnzusatzversicherung', 'Unfallversicherung'],
    actions: ['Policy creation integration', 'Pricing integration']
  });
  const [salesChannelInfo, resetSalesChannelInfo] = refResetter({
    name: 'SalesChannelIntegration',
    application_id: null,
    module_id: null,
    channel: '',
    product: '',
    action: ''
  });
  const loadChannels = () => {
    selectedChannels.value = options.value.channels;
  };

  //-- load info logic --//
  useModuleSidebar(salesChannelInfo, resetSalesChannelInfo, 'Sales Channel');
  watch(() => moduleGraphStore.selectedNodeId, () => {
    loadChannels();
  }, {
    immediate: true
  });

  //-- filters logic --//
  const filtersActive = ref(false);
  const filter = ref({
    name: '',
    category: ''
  });
  const filterByCategory = category => {
    if (filter.value.category === category) {
      filter.value.category = '';
    } else {
      filter.value.category = category;
    }
    selectedChannels.value = options.value.channels.filter(channel => channel.name.toLowerCase().includes(filter.value.name.toLowerCase()) && (filter.value.category === '' || channel.category === filter.value.category));
  };
  const filterByName = () => {
    selectedChannels.value = options.value.channels.filter(channel => channel.name.toLowerCase().includes(filter.value.name.toLowerCase()) && (filter.value.category === '' || channel.category === filter.value.category));
  };

  //-- select channel logic --//
  const nameError = ref(false);
  const selectChannel = async channel => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      if (salesChannelInfo.value.name) {
        nameError.value = false;
        salesChannelInfo.value.channel = channel;
        if (salesChannelInfo.value.channel === 'Check24' && salesChannelInfo.value.product === 'Hundekrankenversicherung' && salesChannelInfo.value.action === 'Policy creation integration') {
          const {
            data: {
              data
            }
          } = await variablesIntegrationService('e09ab532-a4ff-4c4c-9b3f-239707febc74', '47e242c7-08fa-4f02-b4c7-8de98456a4a1');
          await moduleStore.createVariable(appId, moduleId, {
            module_id: moduleId,
            node_id: moduleGraphStore.selectedNodeId,
            node_name: salesChannelInfo.value.name,
            variables: data.data
          });
        } else if (salesChannelInfo.value.channel === 'Check24' && salesChannelInfo.value.product === 'Rechtsschutzversicherung' && salesChannelInfo.value.action === 'Policy creation integration') {
          const {
            data
          } = await variablesIntegrationService('e09ab532-a4ff-4c4c-9b3f-239707febc74', '0e8b3e05-010f-422e-8eaf-a2b45d3cc00d');
          await moduleStore.createVariable(appId, moduleId, {
            module_id: moduleId,
            node_id: moduleGraphStore.selectedNodeId,
            node_name: salesChannelInfo.value.name,
            variables: data.data
          });
        } else if (salesChannelInfo.value.channel === 'Check24' && salesChannelInfo.value.product === 'Fahrradversicherung' && salesChannelInfo.value.action === 'Policy creation integration') {
          const {
            data
          } = await variablesIntegrationService('e09ab532-a4ff-4c4c-9b3f-239707febc74', 'de6063bd-3996-46fd-8fc0-e591184e50b1');
          await moduleStore.createVariable(appId, moduleId, {
            module_id: moduleId,
            node_id: moduleGraphStore.selectedNodeId,
            node_name: salesChannelInfo.value.name,
            variables: data.data
          });
        } else if (salesChannelInfo.value.channel === 'Check24' && salesChannelInfo.value.product === 'Unfallversicherung' && salesChannelInfo.value.action === 'Policy creation integration') {
          const {
            data
          } = await variablesIntegrationService('e09ab532-a4ff-4c4c-9b3f-239707febc74', 'c5b28846-54d9-4659-9eea-6e4f339d6043');
          await moduleStore.createVariable(appId, moduleId, {
            module_id: moduleId,
            node_id: moduleGraphStore.selectedNodeId,
            node_name: salesChannelInfo.value.name,
            variables: data.data
          });
        }
      } else {
        nameError.value = true;
        window.scrollTo(0, 0);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return {
    selectedChannels,
    options,
    salesChannelInfo,
    filtersActive,
    filter,
    filterByCategory,
    filterByName,
    selectChannel
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect
}, __sfc_main.components);
export default __sfc_main;
</script>

<style>
.channel-selected-card {
  border-bottom: 2px solid #5550FC !important;
}
</style>
