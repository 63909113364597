import { ref, watch } from '@vue/composition-api';
import { useRoute } from '@/hooks/vueRouter';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { debouncedWatch } from '@vueuse/core';

// @TO-DO's: refactor this composable, avoid mutating external siderbar state instead return the new state and let components take control of when to save/load info instead of letting watchers do that here

/**
 * @param {import('@vue/composition-api').Ref<object>} sidebarInfoRef
 * @param {function} resetSidebarInfo
 * @param {string} nodeSubType
 */
export const useModuleSidebar = (sidebarInfoRef, resetSidebarInfo, nodeSubType) => {
    const moduleGraphStore = useModuleGraphStore();
    const route = useRoute();

    const avoidSavingInfo = ref(false);
    
    //-- load sidebar logic --//
    const isSidebarInfoLoaded = ref(false);
    const loadSidebarInfo = async () => {
        isSidebarInfoLoaded.value = false;
        const { appId, moduleId } = route.params;
        const nodeInfo = await moduleGraphStore.getNodeInfoById(appId, moduleId, moduleGraphStore.selectedNodeId);
        if (nodeInfo && Object.keys(nodeInfo).length) {
            avoidSavingInfo.value = true;
            sidebarInfoRef.value = {
                ...sidebarInfoRef.value,
                ...nodeInfo
            };
        } else {
            // set sidebar info with default values
            resetSidebarInfo();
        }
        if (!sidebarInfoRef.value.application_id) {
            avoidSavingInfo.value = true;
            sidebarInfoRef.value.application_id = appId;
            sidebarInfoRef.value.module_id = moduleId;
        }
        isSidebarInfoLoaded.value = true;
    };
    watch(
        () => moduleGraphStore.selectedNodeId,
        () => {
            loadSidebarInfo();
        },
        {
            immediate: true
        }
    );

    //-- save sidebar logic --//
    const saveSidebarInfo = async () => {
        const { appId, moduleId } = route.params;
        if (sidebarInfoRef.value.name) {
            const nodeData = {
                ...(moduleGraphStore.selectedNode?.data || {}),
                name: sidebarInfoRef.value.name,
                type: nodeSubType
            };
            if (sidebarInfoRef.value.type === 'screen' || sidebarInfoRef.value?.custom_url_name) {
                nodeData.custom_url_name = sidebarInfoRef.value?.custom_url_name;
            }
            moduleGraphStore.updateSelectedNodeData(nodeData);
            const nodeInfo = await moduleGraphStore.getNodeInfoById(appId, moduleId, moduleGraphStore.selectedNodeId);
            if (nodeInfo) {
                await moduleGraphStore.updateNodeInfo(appId, moduleId, moduleGraphStore.selectedNodeId, sidebarInfoRef.value);
            } else {
                await moduleGraphStore.addNodeInfo(appId, moduleId, moduleGraphStore.selectedNodeId, sidebarInfoRef.value);
            }
        } else {
            window.scrollTo(0, 0);
        }
    };
    debouncedWatch(
        () => sidebarInfoRef.value,
        () => {
            if (avoidSavingInfo.value) {
                avoidSavingInfo.value = false;
            } else {
                saveSidebarInfo();
            }
        },
        {
            deep: true,
            debounce: 500
        }
    );

    return {
        avoidSavingInfo,
        isSidebarInfoLoaded,
        loadSidebarInfo,
        saveSidebarInfo
    };
};
