<template>
  <div class="columns form-builder-content">
    <div
      class="column is-3 element-column"
      style="position: fixed;"
    >
      <FormElements />
    </div>
    <div
      class="column is-6 pb-100 pl-5"
      style="margin-left: 25%"
    >
      <DropZone />
    </div>
    <div
      class="column is-3 property-column"
      style="position: fixed; margin-left: 75%"
    >
      <Properties />
    </div>
  </div>
</template>

<script >
import FormElements from './form-elements/FormElements.vue';
import DropZone from './drop-zone/DropZone.vue';
import Properties from './properties/Properties';

// Stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const moduleGraphStore = useModuleGraphStore();
  return {};
};
__sfc_main.components = Object.assign({
  FormElements,
  DropZone,
  Properties
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.form-builder-content {
  width: 100%;

  .element-column,
  .property-column {
    padding-bottom: 100px;
    height: 100vh !important;
    overflow-y: auto;
    flex: 1!important;

    .group-heading {
      font-size: 1.125rem;
      font-family: 'Roboto', Arial;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }
  }

  .element-column {
    padding: 0;
    background: $grey;
    max-width: 300px!important;

    .element-list {
      padding: 0.75rem;
      padding-left: 1.5rem;

      .draggable-element {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        padding-top: 0.875rem;
        padding-bottom: 0.875rem;
        border: none;
        box-shadow: 0 0.125rem 0.25rem rgba(black, 0.1);
        font-family: 'Roboto', Arial, Helvetica;
        font-size: 0.875rem;
        font-weight: 400;
        background: white;
        cursor: pointer;
      }
    }
  }
}
</style>
