// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { COLUMN_RESOURCE_PATH, DATABASE_SERVICE_ENDPOINT } from './databaseServiceConstants';
import { tableServiceConfig } from './tableRequests';

const columnServiceConfig = {
    ...createHttpServiceConfig(DATABASE_SERVICE_ENDPOINT, ''),
    /**
     * @param {string} databaseId
     * @param {string} tableId
     * @param {string} [columnId]
     */
    getColumnResourcePath: (databaseId, tableId, columnId = '') => `${tableServiceConfig.getTableResourcePath(databaseId, tableId)}${COLUMN_RESOURCE_PATH}${columnId ? '/' + columnId : ''}`
};

//-- request interceptors --//
columnServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} databaseId
 * @param {string} tableId
 * @param {import('../../types/databaseTypes').INewColumns} data
 */
export const createColumnService = (databaseId, tableId, data) => columnServiceConfig.http({
    method: 'POST',
    url: columnServiceConfig.getColumnResourcePath(databaseId, tableId),
    data
});

/**
 * @param {string} databaseId 
 * @param {string} tableId 
 */
export const getColumnsService = (databaseId, tableId) => columnServiceConfig.http({
    method: 'GET',
    url: columnServiceConfig.getColumnResourcePath(databaseId, tableId)
});


/**
 * @param {string} databaseId
 * @param {string} tableId
 * @param {import('../../types/databaseTypes').INewColumns} data
 */
export const updateColumnService = (databaseId, tableId, data) => columnServiceConfig.http({
    method: 'PATCH',
    url: columnServiceConfig.getColumnResourcePath(databaseId, tableId),
    data
});

/**
 * @param {string} databaseId 
 * @param {string} tableId
 * @param {string} columnId
 */
export const deleteColumnService = (databaseId, tableId, columnId) => columnServiceConfig.http({
    method: 'DELETE',
    url: columnServiceConfig.getColumnResourcePath(databaseId, tableId, columnId)
});
