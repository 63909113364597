import { uuid } from 'vue-uuid';
import { createDefaultValue, createDynamicChoices, createIgnoreHiddenFieldValues, createLabel, createLimit, createPlaceholder, createText, createTooltip } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicPropertyUtils';
import { createRequired, createRegex, createInvalidValueMessage } from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/validationPropertyUtils';

export const createSection = () => {
    return {
        columns: '1',
        condition: {
            logicalOperator: 'all',
            children: [],
        },
        show: false,
        fields: [
            [{type: 'hidden'}],
        ]
    };
};

/**
 * @param {import('../../../store/types/formBuilder').FormElementTypes} type 
 */
const createFormElement = (type) => {
    return {
        id: uuid.v4(),
        type,
        show: false,
        condition: {
            logicalOperator: 'all',
            children: [],
        },
        properties: {
            basic: {},
            validation: {}
        }
    };
};

export const createTextInput = () => {
    const textInput = createFormElement('text-input');
    textInput.properties.basic = {
        ...createLabel('Text field'),
        ...createPlaceholder(),
        ...createTooltip(),
        ...createDefaultValue(),
        ...createLimit(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        isDisabled: false
    };
    textInput.properties.validation = {
        ...createRequired(),
        ...createRegex(),
        onlyAllowLetters: false
    };
    return textInput;
};

export const createTextArea = () => {
    const textArea = createFormElement('text-area');
    textArea.properties.basic = {
        ...createLabel('Text area'),
        ...createPlaceholder(),
        ...createTooltip(),
        ...createDefaultValue(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        isDisabled: false
    };
    textArea.properties.validation = {
        ...createRequired(),
        ...createRegex(),
        onlyAllowLetters: false
    };
    return textArea;
};

export const createEmailInput = () => {
    const emailInput = createFormElement('email-input');
    emailInput.properties.basic = {
        ...createLabel('Email'),
        ...createPlaceholder(),
        ...createTooltip(),
        ...createDefaultValue(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        isDisabled: false
    };
    emailInput.properties.validation = {
        ...createRequired(),
        ...createInvalidValueMessage('Value is not an email.'),
        autoValidationCheck: false,
    };
    return emailInput;
};

export const createHTMLEditor = () => {
    const htmlEditor = createFormElement('html-editor');
    htmlEditor.properties.basic = {
        ...createLabel('HTML Editor'),
        ...createPlaceholder(),
        ...createTooltip(),
        ...createDefaultValue(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        isDisabled: false
    };
    htmlEditor.properties.validation = {
        ...createRequired(),
        ...createRegex(),
        onlyAllowLetters: false
    };
    return htmlEditor;
};

export const createNumberInput = () => {
    const numberInput = createFormElement('number-input');
    numberInput.properties.basic = {
        ...createLabel('Number'),
        ...createPlaceholder(),
        ...createTooltip(),
        ...createDefaultValue(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        isDisabled: false,
        showControls: false,
        currencySymbol: '',
        format: '',
        showCurrency: false,
        min: '',
        max: ''
    };
    numberInput.properties.validation = {
        ...createRequired(),
        ...createRegex(),
        ...createInvalidValueMessage('Value is not a number.'),
        rangeErrorMessage: 'Value is not within the specified range.',
        isRangeErrorMessageDynamic: false,
    };
    return numberInput;
};

export const createDropdown = () => {
    const dropdown = createFormElement('dropdown');
    dropdown.properties.basic = {
        ...createLabel('Dropdown'),
        ...createPlaceholder(),
        ...createTooltip(),
        ...createDefaultValue(),
        ...createDynamicChoices(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        multiselect: false,
        hasAutocomplete: false,
        sortOptionsInAlphabeticOrder: false,
    };
    dropdown.properties.validation = {
        ...createRequired()
    };
    return dropdown;
};

export const createRadioBox = () => {
    const radioBox = createFormElement('radio-box');
    radioBox.properties.basic = {
        ...createLabel('Radio'),
        ...createTooltip(),
        ...createDefaultValue(),
        ...createDynamicChoices(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        displayOptionsInRow: false
    };
    radioBox.properties.validation = {
        ...createRequired()
    };
    return radioBox;
};

export const createCheckBox = () => {
    const checkBox = createFormElement('checkbox');
    checkBox.properties.basic = {
        ...createLabel('Checkbox'),
        ...createTooltip(),
        ...createDefaultValue(),
        ...createDynamicChoices(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        displayOptionsInRow: false
    };
    checkBox.properties.validation = {
        ...createRequired()
    };
    return checkBox;
};

export const createDateInput = () => {
    const dateInput = createFormElement('date');
    dateInput.properties.basic = {
        ...createLabel('Date'),
        ...createPlaceholder(),
        ...createTooltip(),
        ...createDefaultValue(new Date()),
        ...createIgnoreHiddenFieldValues(),
        isDefaultValueDynamic: false,
        isPlaceholderDynamic: false,
        format: '',
        width: 'is-12',
        isDisabled: false,
        hasTimePicker: false,
        isTimePickerToggleDynamic: false
    };
    dateInput.properties.validation = {
        ...createRequired(),
        hasRestriction: false,
        restrictedFrom: '',
        restrictedTo: '',
        isRestrictedFromDynamic: false,
        isRestrictedToDynamic: false
    };
    return dateInput;
};

export const createFileUploadInput = () => {
    const fileUploadInput = createFormElement('file-upload');
    fileUploadInput.properties.basic = {
        ...createLabel('File upload'),
        ...createPlaceholder(),
        ...createTooltip(),
        ...createIgnoreHiddenFieldValues(),
        multiple: false,
        width: 'is-12',
    };
    fileUploadInput.properties.validation = {
        ...createRequired(),
        allowAllFormats: true,
        isCustomFormat: false,
        isCustomFormatDynamic: false,
        customFormat: '',
        allowed: []
    };
    return fileUploadInput;
};

export const createPasswordInput = () => {
    const passwordInput = createFormElement('password');
    passwordInput.properties.basic = {
        ...createLabel('Password'),
        ...createPlaceholder(),
        ...createTooltip(),
        ...createDefaultValue(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        buttonToShowPassword: false,
        isDisabled: false
    };
    passwordInput.properties.validation = {
        ...createRequired(),
        ...createRegex()
    };
    return passwordInput;
};

export const createTextField = () => {
    const createTextField = createFormElement('text');
    createTextField.properties.basic = {
        ...createText(),
        width: 'is-12'
    };
    return createTextField;
};

export const createDocument = () => {
    const doc = createFormElement('document');
    doc.properties.basic = {
        document: '',
        width: 'is-12',
        isDynamic: false,
        dynamicDocument: {
            name: '',
            extension: 'pdf',
            baseUrl: process.env.VUE_APP_APP_SERVICE_DOMAIN
        }
    };
    return doc;
};

export const createDataTable = () => {
    const dataTable = createFormElement('data-table');
    dataTable.properties.basic = {
        width: 'is-12',
        pagination: 10,
        tableIsExportable: true,
        exportAllColumns: false,
        tableIsFilterable: true,
        tableIsSortable: true,
        columns: [
            {
                label: 'ID',
                value: '',
                visible: false
            },
            {
                label: '',
                value: '',
                visible: true
            }
        ],
        data: [],
        headers: []
    };
    return dataTable;
};

export const createTermsAndConditionField = () => {
    const tocField = createFormElement('toc');
    tocField.properties.basic = {
        ...createLabel('Accept terms and conditions', true),
        ...createTooltip(),
        ...createDefaultValue(),
        ...createText(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12'
    };
    tocField.properties.validation = {
        ...createRequired()
    };
    return tocField;
};

export const createESignatureField = () => {
    const eSignatureField = createFormElement('e-signature');
    eSignatureField.properties.basic = {
        ...createLabel('Your e-signature', true),
        ...createTooltip(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12'
    };
    eSignatureField.properties.validation = {
        ...createRequired()
    };
    return eSignatureField;
};

export const createIbanField = () => {
    const iBanField = createFormElement('iban');
    iBanField.properties.basic = {
        ...createLabel('IBAN', true),
        ...createTooltip(),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        hasIbanApiValidation: false,
        isIbanApiValidationToggleDynamic: false
    };
    iBanField.properties.validation = {
        ...createRequired(),
        ...createRegex(),
    };
    return iBanField;
};

export const createAddressInput = () => {
    const addressInput = createFormElement('address');
    addressInput.properties.basic = {
        ...createLabel('Address', true),
        ...createTooltip(),
        ...createDefaultValue({
            street: '',
            number: '',
            zipCode: '',
            city: '',
            country: ''
        }),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        labels: {
            street: 'Street',
            number: 'Street number',
            zipCode: 'Zip code',
            city: 'City',
            country: 'Country',
            state: 'State',
            district: 'District'
        },
        hasState : false,
        hasStateDynamic : false,
        hasDistrict : false,
        hasDistrictDynamic : false,
        hasCountry : false,
        hasCountryDynamic : false,
    };
    addressInput.properties.validation = {
        ...createRequired()
    };
    return addressInput;
};

export const createNextButton = () => {
    const { id, type, properties } = createFormElement('next-btn');
    properties.basic = {
        btnText: 'Next',
        isBlock: true,
        clearStateOnSuccess: true
    };
    return {
        id,
        type,
        properties
    };
};

export const createBackButton = () => {
    const { id, type, properties } = createFormElement('back-btn');
    properties.basic = {
        btnText: 'Back',
        isBlock: true,
        clearStateOnSuccess: true
    };
    return {
        id,
        type,
        properties
    };
};

export const createTariffComparisionInput = () => {
    const createTariff = (title) => ({
        title,
        price: '',
        description: '',
        isVisible: true,
        isVisibilityDynamic: false,
        isHighlighted: '',
        isButtonVisible: true,
        isButtonVisibilityDynamic: false
    });
    const tariffComparisionField = createFormElement('tariff-comparison');
    const tariffTitles = ['Bronze', 'Silver', 'Gold', 'Platinum'];
    tariffComparisionField.properties.basic = {
        ...createLabel('', false),
        ...createTooltip('', false),
        ...createIgnoreHiddenFieldValues(),
        width: 'is-12',
        tariffs: tariffTitles.map(title => createTariff(title)),
        highlightedTariffText: '',
        tariffSelectionButton: {
            text: 'Select'
        }
    };
    return tariffComparisionField;
};

export const createIFrame = () => {
    const iFrameField = createFormElement('iframe');
    iFrameField.properties.basic = {
        url: '',
        width: 'is-12'
    };
    return iFrameField;
};

export const createPaymentGateway = () => {
    const paymentGateway = createFormElement('payment-gateway');
    paymentGateway.properties.basic = {
        ...createLabel('Payment Gateway'),
        width: 'is-12',
        serviceProvider: '',
        hasPaymentGateway: true,
        payload: {}
    };
    paymentGateway.properties.validation = {
        ...createRequired()
    };
    return paymentGateway;
};

export const createGrid = () => {
    const grid = createFormElement('grid');
    const ROW_LIMIT = 3;
    const COLUMN_LIMIT = 3;
    grid.properties.basic = {
        ...createLabel('Matrix'),
        width: 'is-12',
        rows: {
            limit: ROW_LIMIT,
            names: Array.from({ length: ROW_LIMIT }, (_, index) => `${index + 1}`)
        },
        columns: {
            limit: COLUMN_LIMIT,
            names: Array.from({ length: COLUMN_LIMIT }, (_, index) => `${String.fromCharCode(index + 65)}`),
            width: Array.from({ length: COLUMN_LIMIT }, () => '')
        },
        hasBorder: {
            vertical: true,
            horizontal: true,
            all: true
        },
        spacing: '', // @NOTE: not implemented now, implement if required
        padding: '', // @NOTE: not implemented now, implement if required
        cells: {
            data: generateGridCells(ROW_LIMIT, COLUMN_LIMIT)
        }
    };
    return grid;
};

export const createGridCell = () => {
    const gridCell = {
        properties: {
            basic: {
                element: {},
                border: {
                    left: true,
                    right: true,
                    top: true,
                    bottom: true
                },
                colSpan: '', // @NOTE: not implemented now, implement if required
                rowSpan: '' // @NOTE: not implemented now, implement if required
            }
        }
    };
    return gridCell;
};

export const createTile = () => {
    const tileField = createFormElement('tile');
    tileField.properties.basic = {
        ...createLabel('Tile'),
        ...createDefaultValue({
            id: '',
            label: '',
        }),
        numberOfCols: 1,
        numberOfRows: 1,
        tilesAlignment: 'is-justify-content-start',
        contentArrangement: 'image-first',
        size: '120',
        imageSize: '50',
        tiles: [
            {id: uuid.v4(), label: 'Title', arrangeImageFirst: true, image: null,image_url: null, file_upload: false },
        ]
    };
    tileField.properties.validation = {
        ...createRequired(),
    };
    return tileField;
};

export const generateGridCells = (rows, columns) => Array.from({ length: rows }, () => Array.from({ length: columns }, () => createGridCell()));
