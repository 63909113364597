<template>
  <div>
    <VueQueryBuilder
      v-model="query"
      :rules="rules"
    >
      <template #default="slotProps">
        <QueryBuilderGroup
          v-bind="slotProps"
          :query.sync="query"
        />
      </template>
    </VueQueryBuilder>
  </div>
</template>

<script >
// TO-DO's: create custom query builder component and replace vue-query-builder 
// Why? 
// Because this component uses an uncommon way for customization which can make it hard to maintain code and debug it for errors - as it relies on extends option of OPTIONS API which suffers from same issues as mixins)
// All this component does is providing UI to generate an object (which can be nested indefinitely) and manage it by providing ways to update, delete and add items in it.
import { ref, watch } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import VueQueryBuilder from 'vue-query-builder';
import QueryBuilderGroup from '@/modules/core/components/query-builder/NuclicoreQueryBuilderGroup';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { transformStringToHtml } from '@/helpers/util';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();

  //-- query logic --//
  const query = ref({
    logicalOperator: 'all',
    children: []
  });
  const loadQuery = () => {
    const connection = moduleGraphStore.selectedConnection;
    avoidLabelUpdate = true;
    if (connection.condition.query && Object.keys(connection.condition.query).length) {
      query.value = connection.condition.query;
    } else {
      query.value = {
        logicalOperator: 'all',
        children: []
      };
    }
  };

  //-- rules logic --//
  const rules = ref([]);
  const createRules = () => {
    rules.value = [{
      type: '',
      id: 'always',
      label: 'Always go through',
      operators: ['']
    }, {
      type: '',
      id: 'random',
      label: 'Random path',
      operators: ['']
    }, ...moduleStore.variables.map(variable => ({
      type: 'text',
      id: variable.reference,
      label: variable.name,
      operators: ['equals', 'does not equal', 'less than', 'more than', 'less than or equal to', 'more than or equal to', 'empty', 'not empty', 'contains', 'does not contain', 'begins with', 'ends with']
    }))];
  };

  //-- save query logic --//
  let avoidLabelUpdate = false;
  debouncedWatch(() => query.value, () => {
    moduleGraphStore.selectedConnection.condition.query = query.value;
    if (avoidLabelUpdate) {
      avoidLabelUpdate = false;
    } else {
      updateLabels();
    }
  }, {
    deep: true,
    debounce: 500
  });
  const updateLabels = () => {
    const doesCurrentConnectionHaveAlwaysCondition = query.value.children.length === 0 || query.value.children.some(qChild => qChild.query.operand === 'Always go through');
    const sourceNode = moduleGraphStore.getNodeByConnectionId(moduleGraphStore.selectedConnectionId);
    if (sourceNode) {
      // update label of selected connection
      const siblingConnections = sourceNode.connections.filter(connection => connection.id !== moduleGraphStore.selectedConnectionId);
      if (doesCurrentConnectionHaveAlwaysCondition) {
        moduleGraphStore.selectedConnection.condition.label = moduleGraphStore.doesAnyConnectionHaveCondition(siblingConnections) ? 'else' : 'always';
      } else {
        moduleGraphStore.selectedConnection.condition.label = query.value.children.map(qChild => `${qChild.query.operand.split('>').slice(-1)[0].trim()} ${getOperatorSymbol(qChild.query.operator)} ${getTextValue(qChild.query.value)}`.trim()).join(' & ');
      }
      // make approriate updates on labels of other connections of same node
      moduleGraphStore.updateConnectionsLabel(sourceNode.connections);
    }
  };

  //-- update connection condition label logic --//
  const symbolMappings = [{
    name: 'equals',
    symbol: '='
  }, {
    name: 'does not equal',
    symbol: '!='
  }, {
    name: 'less than',
    symbol: '<'
  }, {
    name: 'more than',
    symbol: '>'
  }, {
    name: 'less than or equal to',
    symbol: '<='
  }, {
    name: 'more than or equal to',
    symbol: '>='
  }, {
    name: 'empty',
    symbol: '= Ø'
  }, {
    name: 'not empty',
    symbol: '!= Ø'
  }, {
    name: 'contains',
    symbol: '⊆'
  }, {
    name: 'does not contain',
    symbol: '⊄'
  }, {
    name: 'begins with',
    symbol: '^'
  }, {
    name: 'ends with',
    symbol: '$'
  }];
  /**
   * @param {string} operatorName 
   */
  const getOperatorSymbol = operatorName => {
    const symbolMapping = symbolMappings.find(sMapping => sMapping.name === operatorName);
    return symbolMapping ? symbolMapping.symbol : '';
  };
  /**
   * @param {string} htmlText 
   */
  const getTextValue = htmlText => {
    const valueHtml = transformStringToHtml(htmlText);
    return valueHtml ? valueHtml.innerText : '';
  };

  //-- load info logic --//
  const load = () => {
    createRules();
    loadQuery();
  };
  watch(() => moduleGraphStore.selectedConnectionId, () => {
    load();
  }, {
    immediate: true
  });
  return {
    query,
    rules
  };
};
__sfc_main.components = Object.assign({
  VueQueryBuilder,
  QueryBuilderGroup
}, __sfc_main.components);
export default __sfc_main;
</script>
