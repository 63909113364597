<template>
  <div>
    <BaseInput
      v-model="selected.name"
      label="Node name"
    />

    <b-field
      :type="isDuplicateCustomURL ? 'is-danger' : ''"
      :message="isDuplicateCustomURL ? 'Duplicate Custom URL' : ''"
    >
      <BaseInput
        v-model="selected.custom_url_name"
        label="Custom URL name"
        @input="validateCustomURLName"
      />
    </b-field>

    <b-checkbox
      v-model="selected.node_direct_access"
      size="is-small"
      class="has-text-grey"
    >
      Direct Access
    </b-checkbox>

    <BaseSelect
      v-model="selected.layout"
      label="Layout / Master template"
    >
      <option :value="null">
        -- No layout --
      </option>
      <option
        v-for="(ly, index) in layouts"
        :key="index"
        :value="ly.id"
      >
        {{ ly.name }}
      </option>
    </BaseSelect>

    <b-button
      type="is-primary"
      expanded
      @click="isFormBuilderModalActive = true;"
    >
      <b-icon
        class="mr-1"
        icon="pencil-box-multiple-outline"
      />
      Edit Form
    </b-button>
    <FormBuilderModal 
      v-model="isFormBuilderModalActive"
    />
  </div>
</template>

<script >
// libs
import { ref, onMounted, onUnmounted, watch } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
// components
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import FormBuilderModal from '@/modules/builder/components/form-builder/FormBuilderModal.vue';
// services
import { fetchLayoutService } from '@/services/application-service/layoutRequests';

// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';

//-- use composables --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const route = useRoute();
  const {
    selected,
    resetFormBuilderInfo,
    selectedFieldIndices,
    rules
  } = useFormBuilder();

  // custom url
  const isDuplicateCustomURL = ref(false);
  //-- layouts logic --//
  const layouts = ref([]);
  onMounted(async () => {
    const {
      data: {
        data
      }
    } = await fetchLayoutService(route.params.appId);
    layouts.value = data;
  });

  //-- load form logic --//
  const {
    isSidebarInfoLoaded
  } = useModuleSidebar(selected, resetFormBuilderInfo, 'Form builder');
  const load = async () => {
    try {
      isDuplicateCustomURL.value = false;
      validateCustomURLName(selected.value.custom_url_name);
      selectedFieldIndices.value = {
        sectionIndex: null,
        columnIndex: null,
        fieldIndex: null,
        type: null
      };
      const {
        appId,
        moduleId
      } = route.params;
      let screen = await moduleGraphStore.getNodeInfoById(appId, moduleId, moduleGraphStore.selectedNodeId);

      // for existing node
      if (!moduleGraphStore.selectedNode.data.custom_url_name) {
        const url_name = selected.value.custom_url_name ? selected.value.custom_url_name : selected.value.name;
        selected.value.custom_url_name = url_name;
        moduleGraphStore.updateSelectedNodeData({
          type: 'Form builder',
          name: selected.value.name,
          custom_url_name: url_name
        });
        await moduleGraphStore.updateNodeInfo(appId, moduleId, moduleGraphStore.selectedNodeId, selected.value);
      }

      // call only 1st time.
      if (screen && screen?.data?.name && selected.value.name === 'Form') {
        selected.value.name = screen?.data?.name;
        selected.value.custom_url_name = screen?.data?.name;
        moduleGraphStore.updateSelectedNodeData({
          type: 'Form builder',
          name: selected.value.name,
          custom_url_name: selected.value.custom_url_name
        });
        await moduleGraphStore.updateNodeInfo(appId, moduleId, moduleGraphStore.selectedNodeId, selected.value);
      }
      if (screen && screen.info && screen.type === 'Form builder') {
        selected.value.sections.forEach(section => {
          section.fields.forEach(column => {
            column.forEach(field => {
              if (field.type === 'date') {
                if (field.properties.basic.hasDefaultValue) {
                  field.properties.basic.defaultValue = new Date(field.properties.basic.defaultValue);
                }
              }
            });
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  watch(() => isSidebarInfoLoaded.value, () => {
    if (isSidebarInfoLoaded.value) {
      load();
    }
  }, {
    immediate: true
  });
  onUnmounted(() => {
    resetFormBuilderInfo();
  });

  //-- save variables logic --//
  const saveVariables = async () => {
    try {
      if (selected.value.name) {
        let variables = [];
        selected.value.sections.forEach(section => {
          if (section.fields) {
            section.fields.forEach(column => {
              column.forEach(field => {
                if (field.properties?.basic?.label) {
                  if (field.type === 'address') {
                    variables.push({
                      name: field.properties.basic.label + ' > ' + field.properties.basic.labels.street,
                      reference: 'street_' + field.id
                    });
                    variables.push({
                      name: field.properties.basic.label + ' > ' + field.properties.basic.labels.number,
                      reference: 'number_' + field.id
                    });
                    variables.push({
                      name: field.properties.basic.label + ' > ' + field.properties.basic.labels.zipCode,
                      reference: 'zipCode_' + field.id
                    });
                    variables.push({
                      name: field.properties.basic.label + ' > ' + field.properties.basic.labels.city,
                      reference: 'city_' + field.id
                    });
                    variables.push({
                      name: field.properties.basic.label + ' > ' + field.properties.basic.labels.country,
                      reference: 'country_' + field.id
                    });
                    variables.push({
                      name: field.properties.basic.label + ' > ' + field.properties.basic.labels.state,
                      reference: 'state_' + field.id
                    });
                    variables.push({
                      name: field.properties.basic.label + ' > ' + field.properties.basic.labels.district,
                      reference: 'district_' + field.id
                    });
                  } else if (field.type === 'tile') {
                    variables.push({
                      name: field.properties.basic.label + ' > ' + 'id',
                      reference: 'id_' + field.id
                    });
                    variables.push({
                      name: field.properties.basic.label + ' > ' + 'label',
                      reference: 'label_' + field.id
                    });
                  } else if (field.type === 'grid') {
                    variables = variables.concat(field.properties.basic.cells.data.flatMap((row, rowIndex) => row.map((cell, columnIndex) => {
                      const {
                        id,
                        type
                      } = cell.properties.basic.element;
                      if (id && type !== 'text') {
                        // avoids creating variables for empty cells and cells having plain text fields.
                        return {
                          name: `${field.properties.basic.label} > ${field.properties.basic.columns.names[columnIndex]} > ${field.properties.basic.rows.names[rowIndex]}`,
                          reference: cell.properties.basic.element.id
                        };
                      }
                      return null;
                    }).filter(variable => variable)));
                  } else {
                    variables.push({
                      name: field.properties.basic.label,
                      reference: field.id
                    });
                  }
                } else if (field.type === 'tariff-comparison') {
                  variables.push({
                    name: 'Selected tariff > title',
                    reference: 'title_' + field.id
                  });
                  variables.push({
                    name: 'Selected tariff > premium',
                    reference: 'premium_' + field.id
                  });
                  variables.push({
                    name: 'Selected tariff > description',
                    reference: 'description_' + field.id
                  });
                  variables.push({
                    name: 'Selected tariff > protections',
                    reference: 'protections_' + field.id
                  });
                } else if (field.type === 'payment-gateway') {
                  variables.push({
                    name: 'Payment Gateway',
                    reference: field.id
                  });
                }
              });
            });
          }
        });
        const {
          appId,
          moduleId
        } = route.params;
        await moduleStore.createVariable(appId, moduleId, {
          module_id: moduleId,
          node_id: moduleGraphStore.selectedNodeId,
          node_name: selected.value.name,
          variables
        });
      } else {
        window.scrollTo(0, 0);
      }
    } catch (err) {
      console.error(err);
    }
  };
  debouncedWatch(() => selected.value, () => {
    saveVariables();
  }, {
    deep: true,
    debounce: 500
  });
  const validateCustomURLName = val => {
    if (!val || val === '') {
      isDuplicateCustomURL.value = false;
      return;
    }
    const duplicate = moduleGraphStore.screenNodes.find(node => node.nodeId !== moduleGraphStore.selectedNodeId && node.data.custom_url_name === '' + val);
    isDuplicateCustomURL.value = duplicate ? true : false;
  };

  //-- form builder modal --//
  const isFormBuilderModalActive = ref(false);
  return {
    selected,
    isDuplicateCustomURL,
    layouts,
    validateCustomURLName,
    isFormBuilderModalActive
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect,
  FormBuilderModal
}, __sfc_main.components);
export default __sfc_main;
</script>
