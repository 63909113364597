<template>
  <div>
    <div
      v-if="moduleGraphStore.selectedNode"
      class="mt-3 mb-3"
    >
      <div
        class="is-flex is-justify-content-space-between"
      >
        <BaseLabel
          v-if="moduleGraphStore.selectedNode.data.isIgnoreSkippedNodeVariablesDynamic"
          class="mt-2"
        >
          Ignore skipped node values
        </BaseLabel>
        <b-switch
          v-else
          v-model="moduleGraphStore.selectedNode.data.ignoreSkippedNodeVariables"
        >
          Ignore skipped node values
        </b-switch>
        <b-switch
          v-model="moduleGraphStore.selectedNode.data.isIgnoreSkippedNodeVariablesDynamic"
        >
          Dynamic
        </b-switch>
      </div>
      <Editor
        v-if="moduleGraphStore.selectedNode.data.isIgnoreSkippedNodeVariablesDynamic"
        v-model="moduleGraphStore.selectedNode.data.ignoreSkippedNodeVariables"
        :input="true"
        :formula="true"
      />
    </div>
    <ActionSidebar v-if="selectedType === ACTION_NODE_TYPE" />
    <IntegrationSidebar v-else-if="selectedType === INTERGRATION_NODE_TYPE" />
    <ScreenSidebar v-else-if="selectedType === SCREEN_NODE_TYPE" />
    <ConnectionSidebar v-else-if="selectedType === CONNECTION" />
    <StartSidebar v-else-if="selectedType === START_NODE_TYPE" />
    <div v-else>
      <b-notification
        type="is-primary is-light"
        :closable="false"
        class="mt-4 mr-2 has-text-centered"
      >
        Please select
        a node from the builder.
      </b-notification>
    </div>
    <b-message
      v-if="errorMessages.length"
      size="is-small"
      style="position: fixed; bottom: 20px; "
      type="is-danger"
      has-icon
    >
      <ul>
        <li
          v-for="(error, index) in errorMessages"
          :key="index"
        >
          {{ error.message }}
        </li>
      </ul>
    </b-message>
  </div>
</template>

<script >
// libs
import { ref, computed } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
// components
import ActionSidebar from '@/modules/builder/components/module-sidebar/action/ActionSidebar.vue';
import IntegrationSidebar from '@/modules/builder/components/module-sidebar/integration/IntegrationSidebar';
import ScreenSidebar from '@/modules/builder/components/module-sidebar/screen/ScreenSidebar';
import ConnectionSidebar from '@/modules/builder/components/module-sidebar/connection/ConnectionSidebar';
import StartSidebar from '@/modules/builder/components/module-sidebar/start/StartSidebar';
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useRoute } from '@/hooks/vueRouter';
// others
import { ACTION_NODE_TYPE, INTERGRATION_NODE_TYPE, SCREEN_NODE_TYPE, START_NODE_TYPE, CONNECTION } from '@/modules/builder/constants/moduleGraphNodes';

//-- use composables --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const route = useRoute();
  const selectedType = computed(() => {
    if (moduleGraphStore.selectedNodeType) {
      return moduleGraphStore.selectedNodeType;
    } else if (moduleGraphStore.selectedConnectionId) {
      return CONNECTION;
    }
    return '';
  });
  const selectedNodeName = computed(() => moduleGraphStore.selectedNode ? moduleGraphStore.selectedNode?.data?.name : '');
  const updateVariablesName = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      const variables = moduleStore.moduleVariables.filter(variable => variable.node_id === moduleGraphStore.selectedNodeId).map(variable => {
        const {
          id,
          ...restVariable
        } = variable;
        const [_, ...nameList] = restVariable.name.split(' > '); // first item in array is outdated node name which has to be updated and should not be sent to create variable API
        restVariable.name = nameList.join(' > ');
        return restVariable;
      });
      if (variables?.length) {
        await moduleStore.createVariable(appId, moduleId, {
          node_id: moduleGraphStore.selectedNodeId,
          node_name: selectedNodeName.value,
          module_id: moduleId,
          variables
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  debouncedWatch(() => selectedNodeName.value, () => {
    if (selectedNodeName.value) {
      // on change of node name, update variables name of that node
      updateVariablesName();
    }
  }, {
    debounce: 1000
  });
  const errorMessages = ref([]);
  return {
    ACTION_NODE_TYPE,
    INTERGRATION_NODE_TYPE,
    SCREEN_NODE_TYPE,
    START_NODE_TYPE,
    CONNECTION,
    moduleGraphStore,
    selectedType,
    errorMessages
  };
};
__sfc_main.components = Object.assign({
  BaseLabel,
  Editor,
  ActionSidebar,
  IntegrationSidebar,
  ScreenSidebar,
  ConnectionSidebar,
  StartSidebar
}, __sfc_main.components);
export default __sfc_main;
</script>
