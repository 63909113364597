<template>
  <div>
    <BaseSelect
      v-model="selectedScreenType"
      placeholder="Select an action"
      label="Screen type"
      @input="setSelectedScreenType($event)"
    >
      <option
        v-for="(screen, index) in screens"
        :key="index"
      >
        {{ screen }}
      </option>
    </BaseSelect>
    <!-- <FormBuilder v-if="selectedScreenType === 'Form builder'" /> -->
    <ScreenForm v-if="selectedScreenType === 'Form builder'" />
    <SignInForm v-if="selectedScreenType === 'Sign In'" />
  </div>
</template>

<script >
import { computed, ref } from '@vue/composition-api';
// import FormBuilder from '@/modules/builder/components/FormBuilder';
import ScreenForm from '@/modules/builder/components/module-sidebar/screen/ScreenForm.vue';
import SignInForm from '@/modules/builder/components/module-sidebar/screen/SignInForm.vue';
import { useModuleStore, fetchModule } from '@/modules/builder/store/moduleStore';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';

// Services
import { addAuthModuleCustomNodeService, removeAuthModuleAuthNodeService } from '@/services/application-service/module-auth/authModuleRequests';

// composables
import { useRoute } from '@/hooks/vueRouter';

//-- use composables --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const route = useRoute();
  const screens = ref(['Form builder', 'Sign In']);
  const selectedScreenType = computed({
    get() {
      const screen = moduleGraphStore.selectedNode?.data;
      if (screen && screen.type) {
        return screen.type;
      }
      return '';
    },
    set(value) {
      const nodeData = {
        type: value,
        name: ''
      };
      if (moduleGraphStore.selectedNode?.data?.name) {
        nodeData.name = moduleGraphStore.selectedNode.data.name;
      }
      if (moduleGraphStore.selectedNode?.data?.custom_url_name) {
        nodeData.custom_url_name = nodeData.name = moduleGraphStore.selectedNode.data.custom_url_name;
      }
      moduleGraphStore.updateSelectedNodeData(nodeData);
    }
  });
  const setSelectedScreenType = async screenType => {
    const {
      appId,
      moduleId
    } = route.params;
    const tmpModuleDetails = moduleStore.moduleDetails;
    const {
      auth_nodes
    } = moduleStore.moduleDetails;
    let moduleAuthNode = null;
    if (auth_nodes && auth_nodes.nodes.length) {
      moduleAuthNode = auth_nodes.nodes.filter(n => n.nodeId === moduleGraphStore.selectedNodeId);
    }
    if (screenType === 'Sign In') {
      if (!moduleAuthNode?.length) {
        // push module auth node
        const payload = {
          nodeId: moduleGraphStore.selectedNodeId,
          type: screenType
        };
        const addNode = await addAuthModuleCustomNodeService(appId, moduleId, payload);
        tmpModuleDetails.auth_nodes = addNode.data.data.auth_nodes;
      }
    } else if (screenType === 'Form builder') {
      if (moduleAuthNode?.length) {
        const removeNode = await removeAuthModuleAuthNodeService(appId, moduleId, moduleAuthNode[0]);
        tmpModuleDetails.auth_nodes = removeNode.data.data.auth_nodes;
      }
    }
  };
  return {
    screens,
    selectedScreenType,
    setSelectedScreenType
  };
};
__sfc_main.components = Object.assign({
  BaseSelect,
  ScreenForm,
  SignInForm
}, __sfc_main.components);
export default __sfc_main;
</script>

<style>
.select.is-empty select {
  color: hsl(0deg, 0%, 29%) !important;
}
</style>
