<template>
  <div>
    <section>
      <b-collapse
        v-for="(property, propIndex) in formData"
        :key="propIndex"
        :open="false"
        class="mb-2 form-data-builder"
        animation="slide"
      >
        <template #trigger="slotProps">
          <div
            class="mb-2 trigger-container"
            role="button"
            aria-controls="contentIdForA11y3"
          >
            <div
              class="px-2 py-2 is-clipped"
            >
              <span
                v-if="property.name !== ''"
                style="font-weight: 700;"
              >
                {{ property.name }}
              </span>
              <span
                v-else
                style="color: rgb(136, 136, 136);"
              >
                -- enter key name --
              </span>
            </div>
  
            <div class="px-2 py-2 delete-div">
              <b-button @click="deleteProperty(propIndex)">
                <b-icon
                  icon="delete-outline"
                  type="is-danger"
                />
              </b-button>
            </div>
  
            <div class="px-2 py-2">
              <b-icon :icon="slotProps.open ? 'menu-down' : 'menu-up'" />
            </div>
          </div>
        </template>
  
        <template #default>
          <div class="columns mb-0">
            <div class="column">
              <BaseInput
                v-model="property.name"
                label="Key"
                variant="list"
              />
            </div>
            <div class="column">
              <BaseSelect
                v-model="property.type"
                label="Property type"
                variant="list"
              >
                <option
                  value=""
                  selected
                  disabled
                >
                  --select a type--
                </option>
                <option
                  v-for="(type, typeIndex) in types"
                  :key="typeIndex"
                >
                  {{ type }}
                </option>
              </BaseSelect>
            </div>
          </div>
  
          <div v-if="property.type === 'String'">
            <editor
              v-model="property.value"
              :formula="true"
              :input="true"
              label="Value"
            />
          </div>
          <div v-else-if="property.type === 'File'">
            <BaseSelect
              v-model="property.value"
              label="Value"
              variant="list"
            >
              <option
                v-for="(file, fileIndex) in files"
                :key="fileIndex"
                :value="file.nodeId"
              >
                {{ getFileName(file.nodeId) }}
              </option>
            </BaseSelect>
          </div>
        </template>
      </b-collapse>
    </section>
  
    <b-button
      size="is-small"
      expanded
      type="is-primary"
      @click="addProperty()"
    >
      Add property
    </b-button>
  </div>
</template>
  
<script >
// libs
import { computed, ref, watch } from '@vue/composition-api';
import { uuid } from 'vue-uuid';
import { debouncedWatch } from '@vueuse/core';
import { cloneDeep } from 'lodash';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Array,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const route = useRoute();
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const types = ref(['String', 'File']);
  const formData = ref([]);
  let avoidEmittingValue = false;
  const files = computed(() => moduleStore.moduleDocuments || []);
  debouncedWatch(() => props.value, () => {
    avoidEmittingValue = true;
    if (props.value) {
      formData.value = cloneDeep(props.value);
    }
  }, {
    deep: true,
    immediate: true,
    debounce: 300
  });
  debouncedWatch(() => formData.value, () => {
    if (avoidEmittingValue) {
      avoidEmittingValue = false;
    } else {
      emit('input', formData.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  watch(() => moduleGraphStore.selectedNodeId, () => {
    const {
      appId,
      moduleId
    } = route.params;
    moduleStore.fetchModuleDocuments(appId, moduleId);
  }, {
    immediate: true
  });
  const addProperty = () => {
    formData.value.push({
      name: '',
      type: '--select a type--',
      value: '',
      reference: uuid.v4()
    });
  };
  const deleteProperty = index => {
    formData.value.splice(index, 1);
  };
  const stripContent = string => string.replace(/<\/?[^>]+>/ig, '');
  const getFileName = attachmentId => {
    const file = files.value?.find(file => file.nodeId === attachmentId);
    if (file) {
      return stripContent(file.info.name + ' > ' + file.info.fileName + '.' + file.info.extension.toLowerCase());
    }
    return '';
  };
  return {
    types,
    formData,
    files,
    addProperty,
    deleteProperty,
    getFileName
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
  .form-data-builder {
    padding: 1rem 0;
    border-bottom: 1px solid $primary;
  
    .trigger-container {
      display: grid;
      grid-template-columns: 1fr auto auto;
      border: 1px solid #dbdbdb;
      border-radius: 8px;
      max-height: 2.7rem;
      overflow: hidden;
    }
  
    .delete-div button {
      width: 30px;
      height: 30px;
      background: rgba(251, 131, 117, 0.2);
      border-radius: 8px;
      border-color: transparent;
    }
  
    .delete-div button:hover {
      border-color: transparent;
    }
  }
</style>
