<template>
  <div class="document-merger">
    <BaseInput
      v-model="documentMergerInfo.name"
      label="Node name"
    />

    <div
      class="mb-2"
    >
      <BaseLabel>Documents to merge</BaseLabel>
      <Draggable
        v-if="documentMergerInfo.attachments && documentMergerInfo.attachments.length"
        v-model="documentMergerInfo.attachments"
        handle=".drag-handle"
      >
        <div
          v-for="(attachmentId, index) in documentMergerInfo.attachments"
          :key="index"
          class="is-flex mt-2"
        >
          <b-icon
            icon="drag-horizontal-variant"
            class="drag-handle mr-2"
          />
          <DynamicValueSwitch
            :value="documentMergerInfo.attachmentsMeta[attachmentId] ? documentMergerInfo.attachmentsMeta[attachmentId].dynamicValue : ''"
            :is-dynamic="documentMergerInfo.attachmentsMeta[attachmentId] ? documentMergerInfo.attachmentsMeta[attachmentId].isDynamic : false"
            :label="getAttachmentName(attachmentId)"
            class="is-flex-grow-1"
            @input="updateDynamicAttachment(attachmentId, $event)"
            @update-dynamic-switch="updateDynamicAttachmentSwitch(attachmentId, $event)"
          >
            <template #default>
              <div
                v-if="documentMergerInfo.attachmentsMeta[attachmentId] ? !documentMergerInfo.attachmentsMeta[attachmentId].isDynamic : true"
                class="is-flex is-flex-grow-1 is-justify-content-space-between"
              >
                <div class="is-flex">
                  {{ getAttachmentName(attachmentId) }}
                </div>
                <b-icon
                  icon="minus-circle"
                  class="remove-icon mr-2"
                  @click.native.stop="removeSelectedDocument(index)"
                />
              </div>
            </template>
          </DynamicValueSwitch>
        </div>
      </Draggable>
      <div v-else>
        None
      </div>
    </div>

    <div
      v-if="attachments && unselectedAttachments && unselectedAttachments.length"
      class="mb-2"
    >
      <BaseLabel>Documents</BaseLabel>
      <div
        v-for="(attachment, index) in unselectedAttachments"
        :key="index"
        class="mt-2"
      >
        <div class="is-flex is-justify-content-space-between">
          <div>
            {{ getAttachmentName(attachment.nodeId) }}
          </div>
          <b-icon
            icon="plus-circle"
            class="add-icon mr-5"
            type="is-primary"
            @click.native.stop="addDocument(attachment.nodeId)"
          />
        </div>
      </div>
    </div>

    <Editor
      v-model="documentMergerInfo.fileName"
      :input="true"
      :formula="true"
      label="Document's new name"
    />
  </div>
</template>

<script >
// libs
import { computed, set, watch } from '@vue/composition-api';
import Draggable from 'vuedraggable';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { refResetter } from '@/hooks/utils';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
import { useRoute } from '@/hooks/vueRouter';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const route = useRoute();
  const [documentMergerInfo, resetDocumentMergerInfo] = refResetter({
    name: 'DocumentMerger',
    application_id: null,
    module_id: null,
    fileName: '',
    extension: 'PDF',
    attachments: [],
    attachmentsMeta: {}
  });
  const attachments = computed(() => moduleStore.moduleDocuments);
  const unselectedAttachments = computed(() => attachments.value.filter(attachment => !documentMergerInfo.value.attachments.includes(attachment.nodeId)));
  const stripContent = string => string.replace(/<\/?[^>]+>/ig, '');
  const getAttachmentName = attachmentId => {
    const attachment = attachments.value?.find(attachment => attachment.nodeId === attachmentId);
    if (attachment) {
      return stripContent(attachment.info.name + ' > ' + attachment.info.fileName + '.' + attachment.info.extension.toLowerCase());
    }
    return '';
  };
  const addDocument = attachmentId => {
    documentMergerInfo.value.attachments.push(attachmentId);
  };
  const removeSelectedDocument = index => {
    documentMergerInfo.value.attachments.splice(index, 1);
  };
  const updateDynamicAttachment = (attachmentId, value) => {
    if (attachmentId in (documentMergerInfo.value?.attachmentsMeta || {})) {
      documentMergerInfo.value.attachmentsMeta[attachmentId].dynamicValue = value;
    } else {
      set(documentMergerInfo.value.attachmentsMeta, attachmentId, {
        isDynamic: true,
        dynamicValue: ''
      });
    }
  };
  const updateDynamicAttachmentSwitch = (attachmentId, isDynamic) => {
    if (isDynamic) {
      set(documentMergerInfo.value.attachmentsMeta, attachmentId, {
        isDynamic,
        dynamicValue: ''
      });
    } else {
      if (attachmentId in documentMergerInfo.value.attachmentsMeta) {
        delete documentMergerInfo.value.attachmentsMeta[attachmentId];
      }
    }
  };
  watch(() => moduleGraphStore.selectedNodeId, () => {
    const {
      appId,
      moduleId
    } = route.params;
    moduleStore.fetchModuleDocuments(appId, moduleId);
  }, {
    immediate: true
  });

  //-- load info logic --//
  const {
    isSidebarInfoLoaded
  } = useModuleSidebar(documentMergerInfo, resetDocumentMergerInfo, 'Merge Documents');
  watch(() => isSidebarInfoLoaded.value, () => {
    if (isSidebarInfoLoaded.value) {
      // add new properties if they are missing in document merge info
      const newProps = [{
        key: 'attachmentsMeta',
        value: {}
      }];
      newProps.forEach(newProp => {
        if (!(newProp.key in documentMergerInfo.value)) {
          set(documentMergerInfo.value, newProp.key, newProp.value);
        }
      });
    }
  });
  return {
    documentMergerInfo,
    attachments,
    unselectedAttachments,
    getAttachmentName,
    addDocument,
    removeSelectedDocument,
    updateDynamicAttachment,
    updateDynamicAttachmentSwitch
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseLabel,
  Draggable,
  DynamicValueSwitch,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.document-merger {
  .drag-handle {
    border-radius: 0.125rem;
    cursor: pointer;
    &:hover {
      background-color: $grey;
    }
  }
  .remove-icon, .add-icon {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  .remove-icon {
    color: rgba(black, 0.3);
  }
}
</style>
