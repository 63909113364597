<template>
  <div>
    <BaseTreeFlow
      id="moduleGraph"
      v-model="moduleGraphStore.nodes"
      :node-components="nodeComponents"
      :default-condition="moduleGraphStore.defaultCondition"
      :selected-connection-id="moduleGraphStore.selectedConnectionId"
      :delete-connection-button="DeleteConnectionButton"
      :connection-label-component="ConnectionLabel"
      :zoom="zoom"
      @connection-selected="handleConnectionSelect"
    />
    <div
      class="is-flex editor-btn-container"
    >
      <div class="mr-4 cursor-pointer">
        <b-icon
          icon="magnify-plus-outline"
          @click.native="zoomIn()"
        />
      </div>
      <div class="cursor-pointer">
        <b-icon
          icon="magnify-minus-outline"
          @click.native="zoomOut()"
        />
      </div>
    </div>
    <b-button
      v-if="false"
      class="has-shadow box"
      style="position:fixed; bottom: 10px; left: 420px; z-index: 30"
      size="is-small"
    >
      <b-icon
        size="is-small"
        icon="undo"
      />
    </b-button>
    <b-button
      v-if="false"
      class="has-shadow box"
      style="position:fixed; bottom: 10px; left: 465px; z-index: 30"
      size="is-small"
    >
      <b-icon
        size="is-small"
        icon="redo"
      />
    </b-button>
  </div>
</template>

<script >
// libs
import { ref, watch, onUnmounted } from '@vue/composition-api';
// components
import BaseTreeFlow from '@/modules/core/components/generics/base-tree-flow/BaseTreeFlow.vue';
import Start from '@/modules/builder/components/module-graph/nodes/Start';
import Integration from '@/modules/builder/components/module-graph/nodes/Integration';
import Action from '@/modules/builder/components/module-graph/nodes/Action';
import End from '@/modules/builder/components/module-graph/nodes/End';
import Screen from '@/modules/builder/components/module-graph/nodes/Screen';
import DeleteConnectionButton from '@/modules/builder/components/module-graph/DeleteConnectionButton';
import ConnectionLabel from '@/modules/builder/components/module-graph/ConnectionLabel';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
// others
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;

  //-- use composables --//
  const moduleGraphStore = useModuleGraphStore();
  onUnmounted(() => {
    moduleGraphStore.reset();
  });
  const nodeComponents = ref([{
    component: Start,
    name: 'start'
  }, {
    component: Integration,
    name: 'integration'
  }, {
    component: Action,
    name: 'action'
  }, {
    component: Screen,
    name: 'screen'
  }, {
    component: End,
    name: 'end'
  }]);

  //-- tree flow event handling --//
  watch(() => moduleGraphStore.selectedNode, () => {
    if (moduleGraphStore.selectedNode) {
      const {
        nodeId
      } = moduleGraphStore.selectedNode;
      emit('item-selected');
    }
  });
  const handleConnectionSelect = connection => {
    moduleGraphStore.select('connection', connection.id);
    emit('item-selected');
  };

  //-- zoom logic --//
  const zoom = ref(1);
  const maxZoom = 1.2;
  const minZoom = 0.6;
  const zoomStep = 0.1;
  const zoomIn = () => {
    if (zoom.value < maxZoom) {
      zoom.value += zoomStep;
    }
  };
  const zoomOut = () => {
    if (zoom.value > minZoom) {
      zoom.value -= zoomStep;
    }
  };
  return {
    DeleteConnectionButton,
    ConnectionLabel,
    moduleGraphStore,
    nodeComponents,
    handleConnectionSelect,
    zoom,
    zoomIn,
    zoomOut
  };
};
__sfc_main.components = Object.assign({
  BaseTreeFlow
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
  .editor-btn-container {
    position: fixed;
    bottom: 30px;
    left: 565px;
    z-index: 30;
    border-radius: 25px;
    background: white;
    padding: 5px 15px;
  }
  .tree-container {
    .tree-connections {
      .connection-container {
        &.selected {
          .node-connection {
            .connection-line-group {
              .connection-line {
                stroke: $green;
                stroke-width: 2;
              }
            }
          }
          .connection-text {
            color: $green;
          }
        }
        .node-connection {
          .connection-line-group {
            pointer-events: auto;
            &:hover {
              .connection-line {
                stroke: $green;
                stroke-width: 3;
              }
            }
          }
        }
        .connection-text {
          max-width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:hover {
            color: $green;
          }
        }
      }
    }
  }
</style>
