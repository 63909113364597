<template>
  <div>
    <BaseSelect
      v-model="moduleGraphStore.selectedNode.data.type"
      label="Integration type"
    >
      <option
        v-for="(integration, index) in integrations"
        :key="index"
      >
        {{ integration }}
      </option>
    </BaseSelect>

    <SalesChannel v-if="moduleGraphStore.selectedNode.data.type === 'Sales Channel'" />
  </div>
</template>

<script >
import { ref } from '@vue/composition-api';
import SalesChannel from '@/modules/builder/components/module-sidebar/integration/SalesChannel';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const moduleGraphStore = useModuleGraphStore();
  const integrations = ref(['Sales Channel']);
  return {
    moduleGraphStore,
    integrations
  };
};
__sfc_main.components = Object.assign({
  BaseSelect,
  SalesChannel
}, __sfc_main.components);
export default __sfc_main;
</script>
