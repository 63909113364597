import { uuid } from 'vue-uuid';
import { refResetter } from '@/hooks/utils';
import { ref } from '@vue/composition-api';

const [dataMapperInfo, resetDataMapperInfo] = refResetter({
    name: 'DataMapper',
    application_id: null,
    module_id: null,
    dataType: '',
    varsAsSets: false,
    fields: {
        json: [],
        variables: [],
        xml: {
            header: [
                {
                    namespace: '',
                    name: '',
                    value: '',
                    reference: uuid.v4(),
                    properties: [],
                }
            ],
            body: [
                {
                    namespace: '',
                    name: '',
                    value: '',
                    reference: uuid.v4(),
                    properties: [],
                }
            ],
            fault: [
                {
                    namespace: '',
                    name: '',
                    value: '',
                    properties: [
                        {
                            name: '',
                            value: ''
                        }
                    ],
                    // children: []
                }
            ]
        },
        formData: []
    }
});

const shouldAvoidVariablesUpdate = ref(false);

export const useDataMapper = () => {
    return {
        dataMapperInfo,
        shouldAvoidVariablesUpdate,
        resetDataMapperInfo
    };
};
