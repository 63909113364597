<template>
  <div>
    <BaseInput
      v-model="selected.name"
      label="Node name"
    />
    <b-field
      :type="isDuplicateCustomURL ? 'is-danger' : ''"
      :message="isDuplicateCustomURL ? 'Duplicate Custom URL' : ''"
    >
      <BaseInput
        v-model="selected.custom_url_name"
        label="Custom URL name"
        @input="validateCustomURLName"
      />
    </b-field>

    <b-checkbox
      v-model="selected.node_direct_access"
      size="is-small"
      class="has-text-grey"
    >
      Direct Access
    </b-checkbox>
  
    <BaseSelect
      v-model="selected.layout"
      label="Layout / Master template"
    >
      <option :value="null">
        -- No layout --
      </option>
      <option
        v-for="(ly, index) in layouts"
        :key="index"
        :value="ly.id"
      >
        {{ ly.name }}
      </option>
    </BaseSelect>

    <BaseSelect
      :value="selected.additionalInfo.database || ''"
      label="Database"
      @input="updateSelectedDatabase"
    >
      <option
        v-for="database in databaseOptions.databases"
        :key="'database-'+database.id"
        :value="database.id"
      >
        {{ database.name }}
      </option>
    </BaseSelect>
  
    <BaseSelect
      :value="selected.additionalInfo.table || ''"
      label="Tables"
      @input="updateSelectedTable"
    >
      <option
        v-for="(table, tableIdx) in databaseOptions.tables"
        :key="'table-'+tableIdx"
        :value="table.TABLE_NAME"
      >
        {{ table.TABLE_NAME }}
      </option>
    </BaseSelect>
  
    <BaseSelect
      label="Id Column Type"
      :value="selected.additionalInfo.idColumnType || ''"
      @input="updateSelectedIdColumnType($event, 'idColumnType')"
    >
      <option
        v-for="(idColumn, index) in idColumnTypes"
        :key="index+'-'+idColumn.id"
        :value="idColumn.id"
      >
        {{ idColumn.value }}
      </option>
    </BaseSelect>
  
    <BaseSelect
      v-model="selected.additionalInfo.idColumn"
      label="Id Column"
    >
      <option
        v-for="(column, columnIdx) in databaseOptions.columns"
        :key="'column-'+columnIdx"
        :value="column.column_name"
      >
        {{ column.column_name }}
      </option>
    </BaseSelect>
  
    <BaseSelect
      v-model="selected.additionalInfo.passwordColumn"
      label="Password Column"
    >
      <option
        v-for="(column, columnIdx) in databaseOptions.columns"
        :key="'column-'+columnIdx"
        :value="column.column_name"
      >
        {{ column.column_name }}
      </option>
    </BaseSelect>

    <BaseInput
      v-model="selected.additionalInfo.invalidIdPasswordMessage"
      label="Invalid Id or Password Message"
    />

    <BaseSelect
      v-model="selected.additionalInfo.homeModule"
      label="Home Module"
    >
      <option
        v-for="(mdl, idx) in guardedModules"
        :key="'mdl-'+mdl.id+idx"
        :value="mdl.id"
      >
        {{ mdl.name }}
      </option>
    </BaseSelect>
    <div
      class="is-flex m-1"
    >
      <Editor
        v-model="selected.additionalInfo.tokenExpirationTime"
        :formula="false"
        :input="true"
        :label="'Token Expiration Time (Days)'"
        size="is-large"
        class="w-full"
      />
      <b-tooltip
        label="Only supports integers, default is 30 days"
        position="is-left"
      >
        <b-icon
          icon="help-circle-outline"
          size="is-small"
        />
      </b-tooltip>
    </div>

    <b-button
      type="is-primary"
      expanded
      @click="isFormBuilderModalActive = true;"
    >
      <b-icon
        class="mr-1"
        icon="pencil-box-multiple-outline"
      />
      Edit Form
    </b-button>
    <FormBuilderModal 
      v-model="isFormBuilderModalActive"
    />
  </div>
</template>
  
<script >
// libs
import { ref, onMounted, onUnmounted, watch } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';

// components
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import FormBuilderModal from '@/modules/builder/components/form-builder/FormBuilderModal.vue';
import Editor from '@/modules/core/components/wysiwyg/Editor.vue';

// services
import { fetchLayoutService } from '@/services/application-service/layoutRequests';
import { fetchDatabasesService } from '@/services/database-service/databaseRequests';
import { fetchTablesService } from '@/services/database-service/tableRequests';
import { getColumnsService } from '@/services/database-service/columnRequests';

// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';

// composables
import { useRoute } from '@/hooks/vueRouter';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';

// others
import { createSection, createTextInput, createEmailInput, createPasswordInput, createNextButton } from '@/modules/builder/components/form-builder/form-elements/elementFactories';

//-- use composables --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const route = useRoute();
  const {
    selected,
    resetFormBuilderInfo,
    selectedFieldIndices,
    rules
  } = useFormBuilder();

  // custom url
  const isDuplicateCustomURL = ref(false);

  //-- layouts logic --//
  const layouts = ref([]);
  onMounted(async () => {
    const {
      data: {
        data
      }
    } = await fetchLayoutService(route.params.appId);
    layouts.value = data;
    //initSignInForm();
  });

  const databaseOptions = ref({
    databases: [],
    tables: [],
    columns: []
  });

  // Id column types
  const idColumnTypes = ref([{
    id: 'text-input',
    value: 'Text'
  }, {
    id: 'email-input',
    value: 'Email'
  }]);
  const guardedModules = ref([{
    id: null,
    name: '-- No Home --'
  }]);

  // add logic for init SignIn Form
  const initSignInForm = async () => {
    isDuplicateCustomURL.value = false;
    validateCustomURLName(selected.value.custom_url_name);
    if (moduleStore.moduleDetails?.guarded_modules?.idNames) {
      guardedModules.value.push(...moduleStore.moduleDetails.guarded_modules.idNames);
    }
    const {
      appId,
      moduleId
    } = route.params;
    let screen = await moduleGraphStore.getNodeInfoById(appId, moduleId, moduleGraphStore.selectedNodeId);
    //for existing node
    if (!moduleGraphStore.selectedNode.data.custom_url_name) {
      const url_name = selected.value.custom_url_name ? selected.value.custom_url_name : selected.value.name;
      selected.value.custom_url_name = url_name;
      moduleGraphStore.updateSelectedNodeData({
        type: 'Sign In',
        name: selected.value.name,
        custom_url_name: url_name
      });
      await moduleGraphStore.updateNodeInfo(appId, moduleId, moduleGraphStore.selectedNodeId, selected.value);
    }
    if (screen.sections) {
      selected.value.sections = screen.sections;
    } else {
      selected.value.sections = [createSection()];
      selected.value.sections[0].fields[0].push(createTextInput(), createPasswordInput(), createNextButton());
    }
    if (screen.additionalInfo) {
      selected.value.additionalInfo = screen.additionalInfo;
    } else {
      selected.value.name = 'Sign In';
      let num = screen?.data?.name.split('-')[1];
      num = num ? num : '';
      selected.value.custom_url_name = 'Sign-In-' + num;
      moduleGraphStore.updateSelectedNodeData({
        type: 'Sign In',
        name: selected.value.name,
        custom_url_name: selected.value.custom_url_name
      });
      selected.value.additionalInfo = {
        database: '',
        table: '',
        idColumnType: 'text-input',
        idColumn: '',
        passwordColumn: ''
      };
      await moduleGraphStore.updateNodeInfo(appId, moduleId, moduleGraphStore.selectedNodeId, selected.value);
    }
    loadDatabases();
    loadTables();
    loadColumns();
  };
  const updateSelectedDatabase = databaseId => {
    const matchedDatabase = databaseOptions.value.databases.find(db => db.id === databaseId);
    if (matchedDatabase) {
      selected.value.additionalInfo.database = matchedDatabase.id;
      selected.value.additionalInfo.table = '';
      selected.value.additionalInfo.idColumn = '';
      selected.value.additionalInfo.passwordColumn = '';
      loadTables();
    }
  };
  const updateSelectedTable = tableName => {
    const matchedTable = databaseOptions.value.tables.find(table => table.TABLE_NAME === tableName);
    if (matchedTable) {
      selected.value.additionalInfo.table = matchedTable.TABLE_NAME;
      selected.value.additionalInfo.idColumn = '';
      selected.value.additionalInfo.passwordColumn = '';
      loadColumns();
    }
  };
  const updateSelectedIdColumnType = (idColumnTypeValue, idColumnTypeName) => {
    selected.value.additionalInfo[idColumnTypeName] = idColumnTypeValue;
    if (idColumnTypeValue === 'email-input') {
      const {
        sectionIdx,
        sectionColumnIdx,
        fieldIdx
      } = getInputElementSectionAndFieldId('text-input');
      let newEmailInput = createEmailInput();
      newEmailInput.id = selected.value.sections[sectionIdx].fields[sectionColumnIdx][fieldIdx].id;
      selected.value.sections[sectionIdx].fields[sectionColumnIdx][fieldIdx] = newEmailInput;
    } else {
      const {
        sectionIdx,
        sectionColumnIdx,
        fieldIdx
      } = getInputElementSectionAndFieldId('email-input');
      let newTextInput = createTextInput();
      newTextInput.id = selected.value.sections[sectionIdx].fields[sectionColumnIdx][fieldIdx].id;
      selected.value.sections[sectionIdx].fields[sectionColumnIdx][fieldIdx] = newTextInput;
    }
  };
  const getInputElementSectionAndFieldId = inputType => {
    let sectionIdx = -1;
    let sectionColumnIdx = -1;
    let fieldIdx = -1;
    for (let secIdx = 0; secIdx < selected.value.sections.length; secIdx++) {
      const section = selected.value.sections[secIdx];
      for (let columnIdx = 0; columnIdx < section.fields.length; columnIdx++) {
        const columnFields = section.fields[columnIdx];
        for (let fldIdx = 0; fldIdx < columnFields.length; fldIdx++) {
          const elm = columnFields[fldIdx];
          if (inputType == elm.type) {
            sectionIdx = secIdx;
            sectionColumnIdx = columnIdx;
            fieldIdx = fldIdx;
            break;
          }
        }
      }
      if (sectionIdx > -1 && sectionColumnIdx > -1 && fieldIdx > -1) {
        break;
      }
    }
    return {
      sectionIdx,
      sectionColumnIdx,
      fieldIdx
    };
  };
  const loadDatabases = async () => {
    try {
      const {
        appId
      } = route.params;
      const {
        data: {
          data
        }
      } = await fetchDatabasesService({
        application_id: appId
      });
      databaseOptions.value.databases = data;
      if (selected.value.additionalInfo.database) {
        loadTables();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const loadTables = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchTablesService(selected.value.additionalInfo.database);
      databaseOptions.value.tables = data;
    } catch (err) {
      console.error(err);
    }
  };
  const loadColumns = async () => {
    try {
      const {
        data: {
          data
        }
      } = await getColumnsService(selected.value.additionalInfo.database, selected.value.additionalInfo.table);
      databaseOptions.value.columns = data;
    } catch (err) {
      console.error(err);
    }
  };

  //-- load form logic --//
  const {
    isSidebarInfoLoaded
  } = useModuleSidebar(selected, resetFormBuilderInfo, 'Sign In');
  onUnmounted(() => {
    resetFormBuilderInfo();
  });

  //-- form builder modal --//
  const isFormBuilderModalActive = ref(false);

  //-- save variables logic --//
  const saveVariables = async () => {
    try {
      if (selected.value.name) {
        let variables = [];
        selected.value.sections.forEach(section => {
          if (section.fields) {
            section.fields.forEach(column => {
              column.forEach(field => {
                if (field.properties?.basic?.label) {
                  variables.push({
                    name: field.properties.basic.label,
                    reference: field.id
                  });
                }
              });
            });
          }
        });
        const {
          appId,
          moduleId
        } = route.params;
        await moduleStore.createVariable(appId, moduleId, {
          module_id: moduleId,
          node_id: moduleGraphStore.selectedNodeId,
          node_name: selected.value.name,
          variables
        });
      } else {
        window.scrollTo(0, 0);
      }
    } catch (err) {
      console.error(err);
    }
  };
  watch(() => isSidebarInfoLoaded.value, () => {
    if (isSidebarInfoLoaded.value) {
      initSignInForm();
    }
  }, {
    immediate: true
  });
  debouncedWatch(() => selected.value, () => {
    saveVariables();
  }, {
    deep: true,
    debounce: 500
  });
  const validateCustomURLName = val => {
    if (!val || val === '') {
      isDuplicateCustomURL.value = false;
      return;
    }
    const duplicate = moduleGraphStore.screenNodes.find(node => node.nodeId !== moduleGraphStore.selectedNodeId && node.data.custom_url_name === '' + val);
    isDuplicateCustomURL.value = duplicate ? true : false;
  };
  return {
    selected,
    isDuplicateCustomURL,
    layouts,
    databaseOptions,
    idColumnTypes,
    guardedModules,
    updateSelectedDatabase,
    updateSelectedTable,
    updateSelectedIdColumnType,
    isFormBuilderModalActive,
    validateCustomURLName
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect,
  Editor,
  FormBuilderModal
}, __sfc_main.components);
export default __sfc_main;
</script>
<style lang="scss">
@import '~@/style/utilities.scss';
</style>
  