<template>
  <div>
    <small>Node name</small>
    <b-input
      v-model="fileUploaderInfo.name"
      size="is-small"
      class="mb-2"
    />
    <small>Protocol</small>
    <b-select
      v-model="fileUploaderInfo.protocol"
      size="is-small"
      class="mb-2"
      expanded
    >
      <option
        v-for="(protocol, index) in options.protocols"
        :key="index"
      >
        {{ protocol }}
      </option>
    </b-select>
    <div v-if="fileUploaderInfo.protocol === 'FTP' || fileUploaderInfo.protocol === 'SFTP'">
      <small>Operation</small>
      <b-select
        v-model="fileUploaderInfo.operation"
        size="is-small"
        class="mb-2"
        expanded
      >
        <option
          v-for="(operation, index) in options.operations"
          :key="index"
        >
          {{ operation }}
        </option>
      </b-select>
      <small>Host</small>
      <b-input
        v-model="fileUploaderInfo.host"
        size="is-small"
        class="mb-2"
        expanded
      />
      <small>Port</small>
      <b-input
        v-model="fileUploaderInfo.port"
        size="is-small"
        class="mb-2"
        expanded
      />
      <small>Username</small>
      <b-input
        v-model="fileUploaderInfo.username"
        size="is-small"
        class="mb-2"
        expanded
      />
      <small>Password</small>
      <b-input
        v-model="fileUploaderInfo.password"
        size="is-small"
        class="mb-2"
        type="password"
        expanded
      />
      <!-- <small>Source folder structure</small>
      <b-input
        v-model="fileUploaderInfo.localPath"
        size="is-small"
        class="mb-2"
        expanded
      /> -->
      <small>Folder structure</small>
      <b-input
        v-model="fileUploaderInfo.path"
        size="is-small"
        class="mb-2"
        expanded
      />

      <div v-if="fileUploaderInfo.operation === 'Upload' && attachments">
        <b-field
          v-for="(attachment, index) in attachments"
          :key="index"
          expanded
        >
          <b-checkbox
            v-model="fileUploaderInfo.documents"
            size="is-small"
            :native-value="attachment.nodeId"
          >
            {{ stripContent(attachment.info.name + ' > ' + attachment.info.fileName + '.' + attachment.info.extension.toLowerCase())
            }}
          </b-checkbox>
        </b-field>
      </div>

      <div v-if="fileUploaderInfo.operation === 'Download'">
        <small>Remote file to download</small>
        <Editor
          v-model="fileUploaderInfo.remoteFile"
          :input="true"
        />

        <b-button
          size="is-small"
          class="mt-4"
        >
          Test connection
        </b-button>
      </div>
    </div>

    <div v-if="fileUploaderInfo.protocol === 'Local file'">
      <small>Local file</small>
      <b-field>
        <b-upload
          v-model="fileUploaderInfo.file"
          drag-drop
          @change="tryParsing()"
        >
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon
                  icon="upload"
                  size="is-large"
                />
              </p>
              <p>Drop your files here or click to upload</p>
            </div>
          </section>
        </b-upload>
      </b-field>

      <b-switch
        v-model="fileUploaderInfo.multiline"
        size="is-small"
        class="mt-2"
      >
        <small>Parse file's as a multiline</small>
      </b-switch>
    </div>

    <div v-if="fileUploaderInfo.protocol === 'Remote file'">
      <small>File URL</small>
      <b-input
        v-model="fileUploaderInfo.remoteUrl"
        size="is-small"
        placeholder="https://my-remote-server.com/report.csv"
        expanded
      />
      <b-field class="my-2">
        <b-switch
          v-model="fileUploaderInfo.multiline"
          size="is-small"
        >
          <small>Parse file's as a multiline</small>
        </b-switch>
      </b-field>
      <!-- <b-button
        size="is-small"
        block
        @click="tryParsingRemote"
      >
        Parse
      </b-button> -->
    </div>
  </div>
</template>

<script >
import { computed, ref, watch } from '@vue/composition-api';
import Editor from '@/modules/core/components/wysiwyg/Editor';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { refResetter } from '@/hooks/utils';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useRoute } from '@/hooks/vueRouter';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const route = useRoute();
  const options = ref({
    protocols: ['SFTP', 'FTP', 'Local file', 'Remote file'],
    operations: ['Upload', 'Download']
  });
  const [fileUploaderInfo, resetFileUploaderInfo] = refResetter({
    name: 'FileUploader',
    application_id: null,
    module_id: null,
    file: [],
    operation: '',
    protocol: '',
    document: '',
    host: '',
    port: '',
    username: '',
    password: '',
    path: '',
    // localPath: '',
    documents: [],
    remoteFile: '',
    remoteUrl: '',
    multiline: true
  });
  const stripContent = string => string.replace(/<\/?[^>]+>/ig, '');
  const attachments = computed(() => moduleStore.moduleDocuments?.filter(node => node.type === 'action'));
  watch(() => moduleGraphStore.selectedNodeId, () => {
    const {
      appId,
      moduleId
    } = route.params;
    moduleStore.fetchModuleDocuments(appId, moduleId);
  }, {
    immediate: true
  });

  //-- load info logic --//
  useModuleSidebar(fileUploaderInfo, resetFileUploaderInfo, 'Upload File');
  return {
    options,
    fileUploaderInfo,
    stripContent,
    attachments
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
