<template>
  <div
    ref="connectionContainerElement"
    class="connection-container"
    :class="isConnectionSelected ? 'selected' : ''"
    :data-connection-id="connectionId"
    @click.stop="handleConnectionClick()"
  >
    <svg
      ref="connectionSVGElement"
      v-bind="$attrs"
      class="node-connection"
      :class="{ 'is-focused': isConnectionFocused }"
      :style="connectionStyle"
    >
      <g
        class="connection-line-group"
        @mouseover="toggleFocus(true)"
        @mouseleave="toggleFocus(false)"
        @contextmenu.prevent.stop="toggleDeleteConnectionButton($event)"
      >
        <!-- actual lines visibile on screen -->
        <line
          v-for="(lineCoordinate, lIndex) in lineCoordinates"
          v-bind="lineCoordinate"
          :key="'actual' + lIndex"
          class="connection-line"
        />
        <!-- invisible lines intercepting pointer events for actual lines to provide wide hover area -->
        <line
          v-for="(lineCoordinate, lIndex) in lineCoordinates"
          v-bind="lineCoordinate"
          :key="'invisible' + lIndex"
          class="invisible-connection-line"
        />
        <!-- <path 
          :d="connectionPath"
          style="fill: none; stroke-width: 2px; stroke: black;"
        /> -->
        <!-- arrow at the end of connection -->
        <line
          v-for="(arrowCoordinate, aIndex) in arrowCoordinates"
          v-bind="arrowCoordinate"
          :key="'arrow' + aIndex"
          class="connection-line"
        />
      </g>
    </svg>
    <div
      class="connection-text"
      :style="connectionTextPosition"
    >
      <component
        :is="connectionLabelComponent"
        v-if="connectionLabelComponent"
        :condition="connectionCondition"
      />
      <template v-else>
        {{ connectionCondition.label }}
      </template>
    </div>
    <component
      :is="deleteConnectionButton"
      v-if="deleteConnectionButton && showDeleteConnectionButton"
      :connection-id="connectionId"
      class="delete-btn"
      :style="deleteButtonPosition"
    />
  </div>
</template>



<script >
import { computed, nextTick, ref, watch } from '@vue/composition-api';
import { onClickOutside } from '@vueuse/core';
import { getRelativeBoundingRect } from './treeFlowInternal';
import { subscription, ON_CONNECTION_SELECT } from './treeFlowPubSub';
const __sfc_main = {
  inheritAttrs: false
};
__sfc_main.props = {
  lineCoordinates: {
    type: Array,
    default: () => [] // shape of each object: { x1: string, y1: string, x2: string, y2: string }
  },

  connectionCondition: {
    type: Object,
    required: true
  },
  showConnectionText: {
    type: Boolean,
    default: true
  },
  connectionId: {
    type: String,
    required: true
  },
  connectionStyle: {
    type: Object,
    default: () => ({})
  },
  deleteConnectionButton: {
    type: Object,
    default: null
  },
  isConnectionSelected: {
    type: Boolean,
    default: false
  },
  connectionLabelComponent: {
    type: Object,
    default: null
  },
  connectionDirection: {
    type: String,
    default: 'straight'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  /** @type {import('@vue/composition-api').Ref<HTMLElement | null>} */
  const connectionContainerElement = ref(null);
  const arrowCoordinates = computed(() => {
    let coordinates = [];
    if (props.lineCoordinates.length) {
      const {
        x1,
        y1,
        x2,
        y2
      } = props.lineCoordinates.slice(-1)[0];
      const lowerRight = {
        x1: x2 + 5,
        y1: y2 + 5
      };
      const upperLeft = {
        x1: x2 - 5,
        y1: y2 - 5
      };
      const lowerLeft = {
        x1: x2 - 5,
        y1: y2 + 5
      };
      const upperRight = {
        x1: x2 + 5,
        y1: y2 - 5
      };
      if (x1 === x2) {
        if (y2 > y1) {
          // arrow down
          coordinates = [upperLeft, upperRight];
        } else {
          // arrow up
          coordinates = [lowerLeft, lowerRight];
        }
      } else if (y2 === y1) {
        if (x2 > x1) {
          // arrow right
          coordinates = [upperLeft, lowerLeft];
        } else {
          // arrow left
          coordinates = [upperRight, lowerRight];
        }
      }
      if (coordinates.length) {
        coordinates = coordinates.map(cord => ({
          ...cord,
          x2,
          y2
        }));
      }
    }
    return coordinates;
  });

  // const connectionPath = computed(() => {
  //     let pathString = '';
  //     if (Object.keys(props.lineCoordinates).length) {
  //         const { x1, y1, x2, y2 } = props.lineCoordinates;
  //         pathString = `M ${x1} ${y1} l 0 10 q 8 8 -2 12 l ${x2 - 5} 0 q -8 -8 -2 ${x2} l 0 ${y2}`;
  //     }
  //     return pathString;
  // });

  //-- delete button logic --//
  const showDeleteConnectionButton = ref(false);
  /** @type {import('@vue/composition-api').Ref<CSSStyleDeclaration>} */
  const deleteButtonPosition = ref({});

  /**
   * @param {MouseEvent} event 
   */
  const toggleDeleteConnectionButton = event => {
    showDeleteConnectionButton.value = !showDeleteConnectionButton.value;
    deleteButtonPosition.value.top = `${event.offsetY - 20}px`;
    deleteButtonPosition.value.left = `${event.offsetX}px`;
  };

  /** @type {import('@vue/composition-api').Ref<HTMLElement | null>} */
  const connectionSVGElement = ref(null);
  onClickOutside(connectionSVGElement, () => {
    if (showDeleteConnectionButton.value) {
      showDeleteConnectionButton.value = false;
    }
  });

  //-- connection label logic --//
  /** @type {import('@vue/composition-api').Ref<CSSStyleDeclaration>} */
  const connectionTextPosition = ref({});
  watch(() => [props.lineCoordinates, props.connectionCondition.label], async () => {
    try {
      await nextTick();
      const connectionElement = connectionSVGElement.value.querySelector('[data-has-label="true"]');
      if (connectionElement && connectionContainerElement.value) {
        const computedPosition = getLabelPosition(connectionElement);
        connectionTextPosition.value = {
          top: `${computedPosition.top}px`,
          left: `${computedPosition.left}px`
        };
      }
    } catch (err) {
      console.error(err);
    }
  }, {
    deep: true,
    immediate: true
  });
  /** 
   * @param {HTMLElement} connectionElement
   */
  const getLabelPosition = connectionElement => {
    const boundingRect = connectionElement.getBoundingClientRect();
    const relativeBoundingRect = getRelativeBoundingRect(boundingRect, connectionContainerElement.value.parentElement);
    const leftOffset = Math.min(props.connectionCondition.label.length * 3, 50);
    if (props.connectionDirection === 'right') {
      return {
        top: relativeBoundingRect.top + relativeBoundingRect.height / 2 - 22,
        left: relativeBoundingRect.left + leftOffset + 2
      };
    } else if (props.connectionDirection === 'left') {
      return {
        top: relativeBoundingRect.top + relativeBoundingRect.height / 2 - 22,
        left: relativeBoundingRect.left + relativeBoundingRect.width - leftOffset - 2
      };
    }
    return {
      top: relativeBoundingRect.top + relativeBoundingRect.height - 50,
      left: relativeBoundingRect.left
    };
  };

  //-- focus logic --//
  const isConnectionFocused = ref(false);
  /**
   * @param {boolean} focused 
   */
  const toggleFocus = focused => {
    isConnectionFocused.value = focused;
  };

  //-- select connection logic --//
  const handleConnectionClick = () => {
    subscription.publish(ON_CONNECTION_SELECT, props.connectionId);
  };
  return {
    connectionContainerElement,
    arrowCoordinates,
    showDeleteConnectionButton,
    deleteButtonPosition,
    toggleDeleteConnectionButton,
    connectionSVGElement,
    connectionTextPosition,
    isConnectionFocused,
    toggleFocus,
    handleConnectionClick
  };
};
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.connection-container {
  pointer-events: none;
  &.selected {
    .node-connection {
      z-index: 15;
    }
  }
  .node-connection {
    cursor: pointer;
    pointer-events: none;
    overflow: visible;
    z-index: 10;
    transition: all 0.5s;
    &.is-focused {
      z-index: 20;
    }
    .connection-line {
        stroke: black;
        stroke-width: 1;
        stroke-linejoin: round;
        transition: all ease-out 0.2s;
    }
    .invisible-connection-line {
        stroke: transparent;
        stroke-width: 30;
    }
  }
  .connection-text {
    position: absolute;
    transform: translateX(-50%);
    transition: color 0.2s ease-out;
    pointer-events: all;
    cursor: pointer;
    z-index: 30;
  }
  .delete-btn {
    position: absolute;
    pointer-events: all;
    transform: translate(8px, -15px);
  }
}
</style>
