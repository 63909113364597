<template>
  <div>
    <b-notification
      v-if="selectedAction === 'Read/Write To Remote Database'"
      type="is-danger is-light"
      :closable="false"
      class="mb-5 mr-2 has-text-centered"
    >
      <strong>Coming Soon</strong>
    </b-notification>
    <BaseLabel>Action type</BaseLabel>
    <div class="mb-4 sidebar-dropdown">
      <b-dropdown
        v-model="selectedAction"
        :expanded="true"
      >
        <template
          v-if="selectedAction"
          #trigger
        >
          <p>{{ selectedAction }}</p>
        </template>

        <template
          v-else
          #trigger
        >
          <p>&nbsp;</p>
        </template>

        <div
          v-for="(action, index) in actionsList"
          :key="index"
        >
          <b-dropdown-item :value="action.type">
            <div class="dropdown-option-wrap">
              <div class="img-wrap">
                <b-icon :icon="action.icon" />
              </div>
              <div class="text-wrap">
                <h4>{{ action.type }}</h4>
                <p>{{ action.description }}</p>
              </div>
            </div>
          </b-dropdown-item>
        </div>
      </b-dropdown>
    </div>

    <ApiConnector v-if="selectedAction === 'Send API Request'" />
    <ApiBuilder v-if="selectedAction === 'Build an API'" />
    <EmailSender v-if="selectedAction === 'Send Email'" />
    <DataMapper v-if="selectedAction === 'Map/Pair Data'" />
    <FileCreator v-if="selectedAction === 'Create Document'" />
    <DatabaseConnector v-if="selectedAction === 'Read/Write To Remote Database'" />
    <LocalDatabase v-if="selectedAction === 'Local Database Actions'" />
    <ReadFile v-if="selectedAction === 'Read a file'" />
    <MoveFile v-if="selectedAction === 'Move a file'" />
    <DocumentMerge v-if="selectedAction === 'Merge Documents'" />
    <Redirection v-if="selectedAction === 'Redirect'" />
    <SftpUploader v-if="selectedAction === 'Upload File'" />
  </div>
</template>

<script >
// libs
import { computed } from '@vue/composition-api';
// components
import ApiConnector from '@/modules/builder/components/module-sidebar/action/ApiConnector';
import DataMapper from '@/modules/builder/components/module-sidebar/action/data-mapper/DataMapper.vue';
import EmailSender from '@/modules/builder/components/module-sidebar/action/EmailSender';
import FileCreator from '@/modules/builder/components/module-sidebar/action/FileCreator';
import DatabaseConnector from '@/modules/builder/components/module-sidebar/action/DatabaseConnector';
import ApiBuilder from '@/modules/builder/components/module-sidebar/action/ApiBuilder';
import ReadFile from '@/modules/builder/components/module-sidebar/action/ReadFile';
import MoveFile from '@/modules/builder/components/module-sidebar/action/MoveFile';
import LocalDatabase from '@/modules/builder/components/module-sidebar/action/local-database/LocalDatabase.vue';
import DocumentMerge from '@/modules/builder/components/module-sidebar/action/DocumentMerge';
import Redirection from '@/modules/builder/components/module-sidebar/action/Redirection';
import SftpUploader from '@/modules/builder/components/module-sidebar/action/SftpUploader.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
// composables
import { useActionSidebar } from './actionSidebar';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const moduleGraphStore = useModuleGraphStore();
  const {
    actionsList
  } = useActionSidebar();
  const selectedAction = computed({
    get() {
      const action = moduleGraphStore.selectedNode?.data;
      if (action && action.type) {
        return action.type;
      }
      return '';
    },
    set(value) {
      moduleGraphStore.updateSelectedNodeData({
        type: value,
        name: ''
      });
    }
  });
  return {
    actionsList,
    selectedAction
  };
};
__sfc_main.components = Object.assign({
  BaseLabel,
  ApiConnector,
  ApiBuilder,
  EmailSender,
  DataMapper,
  FileCreator,
  DatabaseConnector,
  LocalDatabase,
  ReadFile,
  MoveFile,
  DocumentMerge,
  Redirection,
  SftpUploader
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/modules/core/components/generics/styles/label.scss';
@import '~@/style/variables.scss';

.sidebar-dropdown {
  .dropdown-menu {
    z-index: 1000;
  }

  .dropdown-trigger {
    border: 1px solid $grey-9;
    border-radius: 8px;
    padding: 6px 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $dark;
    position: relative;
  }
  .dropdown-trigger:after {
    border: 3px solid transparent;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    transform-origin: center;
    border-color: $dark;
    margin-top: 0;
    width: 9px;
    height: 9px;
    right: 12px;
  }
  .dropdown-item.is-active {
    background-color: transparent;
    color: $dark;
  }
  .dropdown-option-wrap .img-wrap {
    width: 40px;
    height: 40px;
    padding: 0.5rem;
    border-radius: 8px;
    margin-right: 20px;
  }
  .dropdown-option-wrap {
    display: flex;
    padding: 8px 10px;
    border-radius: 8px;
  }
  .dropdown-option-wrap .text-wrap h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $dark;
    margin-bottom: 4px;
  }

  .dropdown-option-wrap .text-wrap p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $dark;
  }
  .dropdown-item {
    padding: 0 15px;
  }
  .dropdown-item:hover {
    background-color: transparent;
  }
  .dropdown-item:hover .dropdown-option-wrap {
    background-color: $grey;
  }
}
</style>
