// @ts-check
import { MODULE_DEPLOY_STORE_ID } from '@/constants/storeIds';
import { createDeploymentService, fetchDeploymentsService } from '@/services/build-service/deploymentRequests';
import { fetchEnvironmentsService } from '@/services/build-service/environmentRequests';
import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { convertModuleVersionUTCToCestTime } from '@/helpers/util';

export const useModuleDeployStore = defineStore(MODULE_DEPLOY_STORE_ID, () => {
    // compose other stores
    const moduleStore = useModuleStore();
    
    const environmentDeploymentOptions = ref({
        environment_id: '',
        name: '',
        is_protected: false,
        module_id: '',
        version_id: '',
        tag: '',
        deployed_url: '',
        theme: '',
        password_protected: false,
        password: '',
        is_analytics_active: false,
        language: '',
        ipAddresses: ''
    });
    const setEnvironmentDeploymentOptions = (deploymentOptions) => {
        environmentDeploymentOptions.value = deploymentOptions;
    };
    
    const deploymentUrls = ref({
        '1': process.env.VUE_APP_DEPLOYED_APP_URL,
        '2': process.env.VUE_APP_DEPLOYED_API_URL,
        '3': null
    });

    const moduleTypeId = ref('');
    
    const environments = ref([]);
    /**
     * @param {string} moduleId 
     * @param {number} moduleType 
     */
    const getEnvironments = async (moduleId, moduleType) => {
        try {
            environments.value = [];
            const [{ data: { data: envList } }, _] = await Promise.all([
                fetchEnvironmentsService(),
                moduleStore.fetchVersions(moduleId)
            ]);
            const envDetailsList = await Promise.allSettled(
                envList.map(environment => fetchDeploymentsService({
                    module_id: moduleId,
                    environment_id: environment.id
                }))
            );
            environments.value = envDetailsList.map((envDetails, index) => {
                const environment = envList[index];
                if (envDetails.status === 'rejected') {
                    return {
                        id: environment.id,
                        name: environment.name,
                        is_protected: environment.is_protected
                    };
                } else {
                    const { data: { data: envData } } = envDetails.value;
                    return {
                        id: environment.id,
                        name: environment.name,
                        is_protected: environment.is_protected,
                        tag: convertModuleVersionUTCToCestTime(envData.name),
                        deployed_url: deploymentUrls.value[moduleType] ? deploymentUrls.value[moduleType] + envData.id : 'NO PUBLIC FACING URL',
                        theme: envData.theme,
                        password_protected: envData.password_protected,
                        is_analytics_active: envData.is_analytics_active,
                        language: envData.language
                    };
                }
            });
        } catch (err) {
            console.error(err);
        }
    };
    /**
     * @param {string} moduleId 
     * @param {number} moduleType 
     */
    const pushDeployment = async (moduleId, moduleType) => {
        try {
            await Promise.all([
                getEnvironments(moduleId, moduleType),
                createDeploymentService(environmentDeploymentOptions.value)
            ]);
        } catch (err) {
            console.error(err);
        }
    };

    return {
        environmentDeploymentOptions,
        setEnvironmentDeploymentOptions,
        deploymentUrls,
        moduleTypeId,
        environments,
        getEnvironments,
        pushDeployment
    };
});
