<template>
  <div class="h-full">
    <teleport selector="#moduleEditorHeaderContentRight">
      <div class="is-flex is-align-items-center">
        <b-button
          class="px-5 rounded-8 btn-primary-light mr-3"
          size="is-small"
          @click="tryDeploy"
        >
          Deploy
        </b-button>
        <b-tooltip
          :label="moduleStore.moduleView === 'LIST' ? 'Show Graph' : 'Show List'"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer">
            <b-icon
              :icon="moduleStore.moduleView === 'LIST' ? 'graph' : 'format-list-bulleted-square'"
              @click.native="toggleModuleView()"
            />
          </div>
        </b-tooltip>
        <b-tooltip
          label="Variables"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer">
            <b-icon
              icon="at"
              @click.native="isVariableModalActive = true;"
            />
          </div>
        </b-tooltip>
        <b-tooltip
          label="Versions"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer">
            <b-icon
              icon="history"
              @click.native="openModuleVersions()"
            />
          </div>
        </b-tooltip>
        <b-tooltip
          label="Preview"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer">
            <b-icon
              icon="play-circle-outline"
              @click.native="runApp()"
            />
          </div>
        </b-tooltip>
        <b-tooltip
          :label="showSidebar ? 'Hide Sidebar' : 'Show Sidebar'"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer">
            <b-icon
              :icon="showSidebar ? 'chevron-right' : 'chevron-left'"
              @click.native="showSidebar = !showSidebar"
            />
          </div>
        </b-tooltip>
      </div>
    </teleport>
    <b-notification
      v-if="isModuleLocked"
      style="position: fixed; width: 100%; z-index: 999999; bottom: -24px;"
      :closable="false"
      type="is-warning"
    >
      <p>
        This module is locked because another user ({{ lockedUser }}) is currently editing it.
        <b-button
          size="is-small"
          class="is-pulled-right"
          type="is-dark"
          @click="takeOver()"
        >
          Take over and start editing
        </b-button>
      </p>
    </b-notification>
    <div class="columns h-full">
      <div
        class="column"
        :class="[
          showSidebar ? 'main-section-small' : 'main-section-large',
          moduleStore.moduleView === 'GRAPH' ? 'px-3 py-5 has-light-bg' : 'p-0'
        ]"
      >
        <ModuleGraph
          v-if="isModuleFetched"
          v-show="moduleStore.moduleView === 'GRAPH'"
          class="h-full position-relative"
          @item-selected="showSidebar = true;"
        />
        <ModuleNodesList 
          v-show="moduleStore.moduleView === 'LIST'"
          @item-selected="showSidebar = true;"
          @close="toggleModuleView()"
        />
      </div>

      <div
        class="column has-insert-shadow overflow-auto z-30"
        :class="showSidebar ? 'sidebar-section-large' : 'sidebar-section-small'"
        style="padding-bottom: 200px"
      >
        <template v-if="showSidebar">
          <ModuleSidebar />
        </template>
      </div>

      <!-- modals -->
      <SelectDeploymentEnvModal
        v-model="isSelectDeploymentEnvModalActive"
        :module-type-id="typeId"
        @select-deployment="openDeploymentModal"
      />
      <DeploymentModal
        v-model="isDeploymentModalActive"
        :module-type-id="typeId"
        :themes="options.themes"
      />
      <VariablesListModal
        v-model="isVariableModalActive"
        :app-name="appName"
      />
    </div>
  </div>
</template>

<script >
// libs
import { computed, onMounted, onBeforeUnmount, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
// components
import ModuleGraph from '@/modules/builder/components/module-graph/ModuleGraph.vue';
import ModuleSidebar from '@/modules/builder/components/module-sidebar/ModuleSidebar.vue';
import VariablesListModal from '@/modules/builder/components/module-modals/VariablesListModal.vue';
import DeploymentModal from '@/modules/builder/components/module-modals/DeploymentModal.vue';
import SelectDeploymentEnvModal from '@/modules/builder/components/module-modals/SelectDeploymentEnvModal.vue';
import ModuleNodesList from '@/modules/builder/components/module-nodes-list/ModuleNodesList.vue';

// services
import { deleteVariableWithNodeService } from '@/services/application-service/variableRequests';
import { updateModuleService, fetchModuleByIdService } from '@/services/application-service/moduleRequests';
import { fetchThemeService } from '@/services/build-service/themeRequests';
// stores
import { useApplicationStore } from '@/modules/builder/store/applicationStore';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useFormBuilderStore } from '@/modules/builder/store/formBuilderStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useModuleDeployStore } from '@/modules/builder/store/moduleDeployStore';
// composables
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { useSocket } from '@/hooks/vueSocket';
import { useSession } from '@/hooks/vueSession';
// others
import { moduleEventBus, ON_NODE_REMOVED } from '@/modules/builder/common/moduleEventBus';
import { GRAPH_VIEW, LIST_VIEW } from '@/modules/builder/constants/module';

//-- use composables --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const applicationStore = useApplicationStore();
  const formBuilderStore = useFormBuilderStore();
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const moduleDeployStore = useModuleDeployStore();
  const route = useRoute();
  const router = useRouter();
  const socket = useSocket();
  const session = useSession();

  //-- module deploy logic --//
  const isConfirmConvertModalVisible = ref(false);
  const isSelectDeploymentEnvModalActive = ref(false);
  const isDeploymentModalActive = ref(false);
  const tryDeploy = () => {
    isSelectDeploymentEnvModalActive.value = true;
  };
  const openDeploymentModal = () => {
    isDeploymentModalActive.value = true;
  };

  //-- module variables logic --//
  const isVariableModalActive = ref(false);

  //-- module view logic --//
  const showSidebar = ref(true);
  const toggleModuleView = () => {
    if (moduleStore.moduleView === LIST_VIEW) {
      moduleStore.moduleView = GRAPH_VIEW;
      showSidebar.value = true;
    } else {
      moduleStore.moduleView = LIST_VIEW;
      showSidebar.value = false;
    }
  };

  //-- themes logic --//
  const options = ref({
    themes: []
  });
  const getThemes = async () => {
    const {
      data: {
        data
      }
    } = await fetchThemeService();
    options.value.themes = data;
  };

  //-- module CRUD logic --//
  const isModuleFetched = ref(false);
  const lockedUser = computed(() => moduleStore.moduleDetails?.is_locked ? moduleStore.moduleDetails.locked_user : '');
  const isModuleLocked = computed(() => !!lockedUser.value);
  const appName = computed(() => moduleStore.moduleDetails?.name || '');
  const typeId = computed(() => moduleStore.moduleDetails?.type || 0);
  const fetchModule = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      await Promise.all([moduleStore.fetchVariables(appId, moduleId), moduleStore.fetchEnvironmentVariables(appId), moduleStore.fetchModule(appId, moduleId)]);
      await moduleDeployStore.getEnvironments(moduleId, typeId.value);
      // fetch auth module variable, if it's guarded
      if (moduleStore?.moduleDetails?.guarded_module_id) {
        await moduleStore.fetchAuthModuleVariables(appId, moduleStore.moduleDetails.guarded_module_id);
      }
      isModuleFetched.value = true;
    } catch (err) {
      console.error(err);
    }
  };
  const updateModule = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      await moduleStore.updateModule(appId, moduleId);
    } catch (err) {
      console.error(err);
    }
  };
  debouncedWatch(() => moduleGraphStore.nodes, async () => {
    try {
      await updateModule();
    } catch (err) {
      console.error(err);
    }
  }, {
    deep: true,
    debounce: 500
  });
  const takeOver = async () => {
    const {
      appId,
      moduleId
    } = route.params;
    await updateModuleService(appId, moduleId, {}, '?take_over=true');
    await fetchModule();
  };
  const initEditor = () => {
    socket.client.emit('lock', {
      module_id: route.params.moduleId,
      user_id: session.get('id')
    });
    moduleEventBus.subscribe(ON_NODE_REMOVED, handleNodeDeletion);
  };
  const handleNodeDeletion = async deletedNodeId => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      await deleteVariableWithNodeService(appId, moduleId, {
        node_id: deletedNodeId.id
      });
      await moduleStore.fetchVariables(appId, moduleId);
      await updateModule();
    } catch (err) {
      console.error(err);
    }
  };

  //-- miscellaneous --//
  const removeSocket = () => {
    socket.client.emit('unlock', {
      module_id: route.params.moduleId,
      user_id: session.get('id')
    });
  };
  const runApp = () => {
    router.push('/preview/application/' + route.params.appId + '/module/' + route.params.moduleId);
  };
  const openModuleVersions = () => {
    router.push('/application/' + route.params.appId + '/module/' + route.params.moduleId + '/versions');
  };
  onMounted(async () => {
    applicationStore.reset();
    formBuilderStore.reset();
    moduleStore.reset();
    initEditor();
    const {
      moduleId
    } = route.params;
    if (route.params.moduleId) {
      await fetchModule();
      getThemes();
    }
  });
  onBeforeUnmount(() => {
    removeSocket();
    moduleGraphStore.reset();
  });
  return {
    moduleStore,
    isSelectDeploymentEnvModalActive,
    isDeploymentModalActive,
    tryDeploy,
    openDeploymentModal,
    isVariableModalActive,
    showSidebar,
    toggleModuleView,
    options,
    isModuleFetched,
    lockedUser,
    isModuleLocked,
    appName,
    typeId,
    takeOver,
    runApp,
    openModuleVersions
  };
};
__sfc_main.components = Object.assign({
  ModuleGraph,
  ModuleNodesList,
  ModuleSidebar,
  SelectDeploymentEnvModal,
  DeploymentModal,
  VariablesListModal
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/components.scss';

.has-light-bg {
  background: #ededed !important;
}

.has-insert-shadow {
  -webkit-box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
}

.main-section-large {
  flex: none;
  width: 100%;
  transition-property: width;
  transition: 0.4s ease;
}

.main-section-small {
  flex: none;
  width: 70%;
  transition-property: width;
  transition: 0.4s ease;
}

.sidebar-section-large {
  flex: none;
  transition-property: width;
  width: 30%;
  transition: 0.4s ease;
}

.sidebar-section-small {
  flex: none;
  width: 0%;
  transition-property: width;
  transition: 0.4s ease;
}
</style>
