<template>
  <div>
    <b-switch v-model="basicProperties.isDynamic">
      Is name dynamic?
    </b-switch>
    <div 
      v-if="basicProperties.isDynamic"
    >
      <Editor
        v-model="basicProperties.dynamicDocument.name"
        :input="true"
        :formula="true"
      />
    </div>
    <BaseSelect
      v-else
      v-model="basicProperties.document"
      label="Select a document to show"
      expanded
    >
      <option
        v-for="(document, index) in documents"
        :key="index"
        :value="stripContent(baseUrl + '/' + document.info.fileName + '.' + document.info.extension.toLowerCase())"
      >
        {{ stripContent(document.info.name + ' > ' + document.info.fileName + '.' + document.info.extension.toLowerCase()) }}
      </option>
    </BaseSelect>
  </div>
</template>

<script >
// libs
import { computed, onMounted, ref } from '@vue/composition-api';
// components
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import Editor from '@/modules/core/components/wysiwyg/Editor.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const moduleStore = useModuleStore();
  const route = useRoute();
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  const baseUrl = ref(process.env.VUE_APP_APP_SERVICE_DOMAIN);
  const stripContent = string => string.replace(/<\/?[^>]+>/ig, '');
  const documents = computed(() => moduleStore.moduleDocuments || []);
  onMounted(() => {
    const {
      appId,
      moduleId
    } = route.params;
    moduleStore.fetchModuleDocuments(appId, moduleId);
  });
  return {
    basicProperties,
    baseUrl,
    stripContent,
    documents
  };
};
__sfc_main.components = Object.assign({
  Editor,
  BaseSelect
}, __sfc_main.components);
export default __sfc_main;
</script>
