export const createRequired = (isRequiredMessage = 'This field is required.', isRequired = true, isRequiredMessageDynamic = false) => ({
    isRequired,
    isRequiredMessage,
    isRequiredMessageDynamic
});

export const createRegex = (hasRegexMessage = 'This value is not allowed.', regex = '', hasRegex = false, isRegexMessageDynamic = false) => ({
    hasRegex,
    hasRegexMessage,
    regex,
    isRegexMessageDynamic
});

export const createInvalidValueMessage = (message = 'Value is not valid', isMessageDynamic = false) => ({
    message,
    isMessageDynamic
});
