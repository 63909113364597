<template>
  <div>
    <BaseInput
      v-model="dataMapperInfo.name"
      label="Node name"
    />

    <BaseSelect
      v-model="dataMapperInfo.dataType"
      label="Data type"
      @input="saveVariables"
    >
      <option
        v-for="(dataType, index) in options.dataTypes"
        :key="index"
      >
        {{ dataType }}
      </option>
    </BaseSelect>

    <template v-if="dataMapperInfo.dataType === 'Variables'">
      <VariablesBuilderBlock :variables="dataMapperInfo.fields.variables" />
    </template>

    <template v-if="dataMapperInfo.dataType === 'JSON'">
      <JsonBuilderBlock
        v-model="dataMapperInfo.fields.json"
      />
    </template>

    <div v-if="dataMapperInfo.dataType === 'XML'">
      <XmlBuilderBlock />
    </div>
    <template v-if="dataMapperInfo.dataType === 'FormData'">
      <FormDataBuilderBlock
        v-model="dataMapperInfo.fields.formData"
      />
    </template>
  </div>
</template>


<script >
// libs
import { ref, onUnmounted, computed } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
// components
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import JsonBuilderBlock from '@/modules/builder/components/module-sidebar/action/data-mapper/JsonBuilderBlock.vue';
import VariablesBuilderBlock from '@/modules/builder/components/module-sidebar/action/data-mapper/VariablesBuilderBlock.vue';
import XmlBuilderBlock from '@/modules/builder/components/module-sidebar/action/data-mapper/XmlBuilderBlock.vue';
import FormDataBuilderBlock from '@/modules/builder/components/module-sidebar/action/data-mapper/FormDataBuilderBlock.vue';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useRoute } from '@/hooks/vueRouter';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
import { useDataMapper } from './dataMapper';

//-- use composables --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const {
    dataMapperInfo,
    resetDataMapperInfo,
    shouldAvoidVariablesUpdate
  } = useDataMapper();
  const options = ref({
    dataTypes: ['Variables', 'JSON', 'XML', 'FormData'],
    xml: {
      languages: ['DTD', 'W3C XML', 'RELAX NG']
    }
  });

  //-- load info logic --//
  useModuleSidebar(dataMapperInfo, resetDataMapperInfo, 'Map/Pair Data');
  onUnmounted(() => {
    resetDataMapperInfo();
  });

  //-- variables logic --//
  const variablesBlock = computed(() => dataMapperInfo.value?.fields?.variables || []);
  const createVariables = async variables => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      await moduleStore.createVariable(appId, moduleId, {
        module_id: moduleId,
        node_id: moduleGraphStore.selectedNodeId,
        node_name: dataMapperInfo.value.name,
        variables
      });
    } catch (err) {
      console.error(err);
    }
  };
  debouncedWatch(() => variablesBlock.value, () => {
    saveVariables();
  }, {
    deep: true,
    debounce: 500
  });
  const saveVariables = async () => {
    if (shouldAvoidVariablesUpdate.value) {
      shouldAvoidVariablesUpdate.value = false;
    } else {
      if (dataMapperInfo.value.name) {
        let variables = [];
        if (dataMapperInfo.value.dataType === 'XML/SOAP') {
          variables = dataMapperInfo.value.fields.xml.header.filter(field => field.name !== '').map(field => ({
            reference: field.reference,
            name: field.namespace + ':' + field.name
          }));
          variables = variables.concat(dataMapperInfo.value.fields.xml.body.filter(field => field.name !== '').map(field => ({
            reference: field.reference,
            name: field.namespace + ':' + field.name
          })));
        } else if (dataMapperInfo.value.dataType === 'JSON') {
          const existingJsonVariable = moduleStore.variables.find(variable => variable.node_id === moduleGraphStore.selectedNodeId && variable.reference.includes(moduleGraphStore.selectedNodeId));
          const jsonBodyVariable = [{
            reference: existingJsonVariable ? existingJsonVariable.reference : moduleGraphStore.selectedNodeId + 'JSON',
            name: dataMapperInfo.value.name
          }];
          await deleteStaleVariables();
          await createVariables(jsonBodyVariable);
        } else if (dataMapperInfo.value.dataType === 'Variables') {
          const allVars = dataMapperInfo.value.fields.variables.map(variable => ({
            reference: variable.reference,
            name: variable.name
          }));
          await deleteStaleVariables();
          await createVariables(allVars);
        } else if (dataMapperInfo.value.dataType === 'FormData') {
          const formDataVariable = [{
            reference: moduleGraphStore.selectedNodeId + 'FormData',
            name: 'FormData'
          }];
          await deleteStaleVariables();
          await createVariables(formDataVariable);
        }
      }
    }
  };
  const deleteStaleVariables = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;
      let references = [];
      if (dataMapperInfo.value.dataType !== 'Variables') {
        references = dataMapperInfo.value.fields.variables.map(variable => variable.reference);
      } else if (dataMapperInfo.value.dataType !== 'JSON') {
        const existingJsonVariable = moduleStore.variables.find(variable => variable.node_id === moduleGraphStore.selectedNodeId && variable.reference.includes(moduleGraphStore.selectedNodeId));
        references.push(existingJsonVariable ? existingJsonVariable.reference : moduleGraphStore.selectedNodeId + 'JSON');
      } else if (dataMapperInfo.value.dataType !== 'FormData') {
        references.push(moduleGraphStore.selectedNodeId + 'FormData');
      }
      await moduleStore.deleteVariables(appId, moduleId, references);
    } catch (err) {
      console.error(err);
    }
  };
  return {
    dataMapperInfo,
    options,
    saveVariables
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect,
  VariablesBuilderBlock,
  JsonBuilderBlock,
  XmlBuilderBlock,
  FormDataBuilderBlock
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
  // Classess prefixed with sidebar-* can be found at ApiConnector
</style>
