<template>
  <div v-if="basicProperties.columns">
    <div
      class="mt-2"
    >
      <BaseLabel class="column is-6 p-0">
        Unique ID
      </BaseLabel>
      <div>
        <Editor
          v-model="basicProperties.columns[0].value"
          :input="true"
        />
      </div>
    </div>
    <div
      class="mt-2"
    >
      <div class="is-flex is-justify-content-space-between">
        <BaseLabel class="mt-2">
          Columns
        </BaseLabel>
        <b-button
          class="mt-2"
          size="is-small"
          type="is-primary"
          :disabled="!idColumnValue"
          @click="autoMap()"
        >
          Auto Map
        </b-button>
      </div>
      <div class="data-table-column mt-2">
        <BaseLabel class="column is-6 p-0">
          Label
        </BaseLabel>
        <BaseLabel class="column is-5 p-0">
          Value
        </BaseLabel>
      </div>
      <Draggable
        v-model="basicProperties.columns"
        handle=".drag-handle"
      >
        <template v-for="(label, index) in basicProperties.columns">
          <div
            v-if="index > 0"
            :key="index"
            class="data-table-column mt-2"
          >
            <b-icon
              icon="drag-horizontal-variant"
              class="drag-handle mr-1"
            />
            <BaseInput
              v-model="basicProperties.columns[index].label"
              type="text"
            />
            <Editor
              v-model="basicProperties.columns[index].value"
              :input="true"
              class="ml-2"
            />
            <b-button
              type="is-danger"
              size="is-small"
              icon-right="delete"
              class="ml-2"
              @click="deleteColumn(index)"
            />
          </div>
        </template>
      </Draggable>
      <b-button
        class="mt-2"
        size="is-small"
        expanded
        type="is-primary"
        @click="addColumn()"
      >
        Add Column
      </b-button>
    </div>
  </div>
</template>

<script >
// libs
import { computed } from '@vue/composition-api';
import Draggable from 'vuedraggable';
// components
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// composables
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
// others
import { extractReferenceId } from '@/helpers/ExpressionParser';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    basicProperties
  } = useBasicProperties();
  const moduleStore = useModuleStore();
  const idColumnValue = computed(() => {
    if (basicProperties.value?.columns?.length && basicProperties.value.columns[0].value) {
      const idReference = extractReferenceId(basicProperties.value.columns[0].value);
      return idReference;
    }
    return null;
  });
  const localDBNodeId = computed(() => {
    if (idColumnValue.value) {
      return idColumnValue.value.split('-').slice(0, 5).join('-');
    }
    return '';
  });
  const deleteColumn = index => {
    basicProperties.value.columns.splice(index, 1);
  };
  const addColumn = () => {
    basicProperties.value.columns.push({
      label: '',
      value: '',
      visible: true
    });
  };
  const autoMap = () => {
    if (localDBNodeId.value) {
      const columnMappings = moduleStore.moduleVariables.filter(variable => variable.node_id === localDBNodeId.value && variable.reference !== idColumnValue.value).map(variable => ({
        label: variable.name.split(' > ').slice(-1)[0],
        value: `<p><span class="mention" data-mention-id="${variable.reference}" name="${variable.name}">@${variable.name.replaceAll(' > ', ' &gt; ')}</span> </p>`,
        visible: true
      }));
      // clear existing mapping aside from ID column
      basicProperties.value.columns = basicProperties.value.columns.slice(0, 1);
      // assign auto mapped columns
      basicProperties.value.columns = basicProperties.value.columns.concat(columnMappings);
    }
  };
  return {
    basicProperties,
    idColumnValue,
    deleteColumn,
    addColumn,
    autoMap
  };
};
__sfc_main.components = Object.assign({
  BaseLabel,
  Editor,
  Draggable,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.data-table-column {
  display: flex;

  > div {
    flex: 1;
  }
  .drag-handle {
    cursor: pointer; 
  }
}
</style>
