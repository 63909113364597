<template>
  <div>
    <!-- <small>Cron job time</small> -->

    <BaseSelect
      v-model="schedulerInfo.cronJob.minute"
      label="Minute"
    >
      <option value="*">
        Any
      </option>
      <option
        v-for="(n, index) in 60"
        :key="index"
      >
        {{ index }}
      </option>
    </BaseSelect>

    <BaseSelect
      v-model="schedulerInfo.cronJob.hour"
      label="Hour (24h-Format)"
    >
      <option value="*">
        Any
      </option>
      <option
        v-for="(n, index) in 24"
        :key="index"
      >
        {{ index }}
      </option>
    </BaseSelect>

    <BaseSelect
      v-model="schedulerInfo.cronJob.day"
      label="Day"
    >
      <option value="*">
        Any
      </option>
      <option
        v-for="(n, index) in 31"
        :key="index"
      >
        {{ index }}
      </option>
    </BaseSelect>

    <BaseSelect
      v-model="schedulerInfo.cronJob.month"
      label="Month"
    >
      <option value="*">
        Any
      </option>
      <option
        v-for="(n, index) in 12"
        :key="index"
        :value="index"
      >
        {{ n }}
      </option>
    </BaseSelect>

    <BaseSelect
      v-model="schedulerInfo.cronJob.weekday"
      label="Weekday"
    >
      <option value="*">
        Any
      </option>
      <option
        v-for="(day, index) in ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
        :key="index - 1"
        :value="index"
      >
        {{ day }}
      </option>
    </BaseSelect>
  </div>
</template>

<script >
// components
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
// composables
import { refResetter } from '@/hooks/utils';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const [schedulerInfo, resetSchedulerInfo] = refResetter({
    name: 'Scheduler',
    executionTime: 'Always',
    cronJob: {
      minute: '*',
      hour: '*',
      day: '*',
      month: '*',
      weekday: '*'
    }
  });
  useModuleSidebar(schedulerInfo, resetSchedulerInfo, 'Specific time / Cron job');
  return {
    schedulerInfo
  };
};
__sfc_main.components = Object.assign({
  BaseSelect
}, __sfc_main.components);
export default __sfc_main;
</script>
