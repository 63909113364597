<template>
  <div>
    <b-datepicker
      ref="datepicker"
      size="is-small"
      expanded
      editable
      locale="de-DE"
      :disabled="field.properties.basic.isDisabled"
      :placeholder="field.properties.basic.hasPlaceholder ? formatPlaceholder(field.properties.basic.placeholder) : ''"
      :date-formatter="formatDate()"
      :unselectable-dates="getRestrictedDates(field.properties.validation.restrictedFrom, field.properties.validation.restrictedTo)"
      :time-picker="{ hourFormat }"
    >
      <template
        v-if="field.properties.basic.hasTimePicker"
        #default
      >
        <div class="is-flex is-justify-content-center">
          <b-timepicker
            :hour-format="hourFormat"
            inline
          />
        </div>
      </template>
    </b-datepicker>
    <BaseInput
      :key="`date_${uuid.v4()}_${date}`"
      :mask="maskConfig"
      :value="date"
      class="is-hidden"
      @mask-value-update="updateDatePicker"
    />
    <BaseInput
      :key="`time_${uuid.v4()}_${time}`"
      :mask="timeMaskConfig"
      :value="time"
      class="is-hidden"
      @mask-value-update="updateTimePicker"
    />
  </div>
</template>

<script >
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api';
import moment from 'moment';
import { uuid } from 'vue-uuid';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const date = ref('');
  const time = ref('');
  const datepicker = ref(null);
  const maskConfig = computed(() => {
    const fullFormat = props.field.properties.basic.format;
    const [dateFormat, _timeFormat] = fullFormat.split(/\s+/);
    if (!dateFormat) {
      return {};
    }
    let delimiters = dateFormat.match(/\W/g) || ['.'];
    let delimiter = delimiters[0];
    const datePattern = dateFormat.split(/\W/).map(part => {
      if (part.toLowerCase().includes('d')) {
        return 'd';
      }
      if (part.toLowerCase().includes('m')) {
        return 'm';
      }
      if (part.toLowerCase().includes('y')) {
        return 'Y';
      }
      return '';
    }).filter(part => part);
    return {
      date: true,
      delimiter: delimiter,
      datePattern
    };
  });
  const timeMaskConfig = computed(() => {
    const fullFormat = props.field.properties.basic.format;
    const [_dateFormat, timeFormat] = fullFormat.split(/\s+/);
    if (!timeFormat) {
      return {};
    }

    // Define default time delimiters and patterns
    let timeDelimiter = ':';
    let timePattern = [];

    // Generate the time pattern based on the format
    timePattern = timeFormat.split(/:/).map(part => {
      if (part.toLowerCase().includes('h')) {
        return part.length === 2 ? 'HH' : 'H'; // Adjust for hour format (24-hour or 12-hour)
      }

      if (part.toLowerCase().includes('m')) {
        return 'MM';
      }
      if (part.toLowerCase().includes('s')) {
        return 'SS';
      }
      return '';
    }).filter(part => part);

    // Assuming the delimiter for time is always ':'
    timeDelimiter = ':';
    return {
      time: true,
      delimiter: timeDelimiter,
      pattern: timePattern
    };
  });
  const hourFormat = computed(() => {
    const fullFormat = props.field.properties.basic.format;
    if (fullFormat?.indexOf('h') !== -1) {
      return '12';
    } else {
      return '24';
    }
  });
  const getRestrictedDates = (restrictedFrom, restrictedTo) => {
    const restrictedFromDate = new Date(restrictedFrom);
    const restrictedToDate = restrictedTo ? new Date(restrictedTo) : null;
    return day => {
      let isDateDisabled = false;
      if (restrictedFromDate && restrictedFromDate.getTime() > day.getTime()) {
        isDateDisabled = true;
      }
      if (restrictedToDate && restrictedToDate.getTime() < day.getTime()) {
        isDateDisabled = true;
      }
      return isDateDisabled;
    };
  };
  const formatDate = () => {
    let dateFormat = props.field.properties.basic.format;
    if (dateFormat) {
      return date => moment(date).format(dateFormat);
    } else {
      return date => moment(date).format('DD.MM.YYYY');
    }
  };
  const onInput = event => {
    const inputVal = event.target.value;
    if (!inputVal) return;
    const inputArr = inputVal.split(' ');
    if (inputArr?.[0]) {
      date.value = inputArr[0];
    }
    if (inputArr?.[1]) {
      time.value = inputArr[1];
    }
  };
  const formatPlaceholder = dateString => {
    const date = moment(dateString);
    if (date.isValid()) {
      return date.format(props.field.properties.basic.format || 'DD.MM.YYYY');
    } else {
      return dateString;
    }
  };
  const updateDatePicker = value => {
    const inputElement = datepicker.value?.$el.querySelector('input');
    if (inputElement) {
      const tempArr = [...inputElement.value.split(' ')];
      tempArr[0] = value;
      inputElement.value = tempArr.join(' ');
    }
  };
  const updateTimePicker = value => {
    const inputElement = datepicker.value?.$el.querySelector('input');
    if (inputElement) {
      const tempArr = [...inputElement.value.split(' ')];
      if (tempArr?.[1]) {
        tempArr[1] = value;
        inputElement.value = tempArr.join(' ');
      }
    }
  };
  onMounted(() => {
    const inputElement = datepicker.value?.$el.querySelector('input');
    if (inputElement) {
      inputElement.addEventListener('input', onInput);
    }
  });
  onUnmounted(() => {
    const inputElement = datepicker.value?.$el.querySelector('input');
    if (inputElement) {
      inputElement.removeEventListener('input', onInput);
    }
  });
  return {
    uuid,
    date,
    time,
    datepicker,
    maskConfig,
    timeMaskConfig,
    hourFormat,
    getRestrictedDates,
    formatDate,
    formatPlaceholder,
    updateDatePicker,
    updateTimePicker
  };
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
